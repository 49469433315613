
const months: { [index: number]: string } = {
    0: "Jan",
    1: "Feb",
    2: "Mar",
    3: "Apr",
    4: "May",
    5: "June",
    6: "July",
    7: "Aug",
    8: "Sept",
    9: "Oct",
    10: "Nov",
    11: "Dec"
}

export const formatDate = (s: string): string => {
    const date = new Date(s)
    var month = months[date.getMonth()]
    var year = date.getFullYear()
    var dayOfMonth = date.getDate()
    return month + " " + dayOfMonth + " • " + year
}
