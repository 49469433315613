import React from "react";
import { Redirect } from "react-router";
import { Grid, Box } from "@mui/material";
import ProfileSettings from "./ProfileSettings";
import AccountSettings from "./AccountSettings";
import TopBar from "../TopBar";
import { MAX_PAGE_WIDTH } from "../constants";
import { useAuth } from "./AuthProvider";

const SettingsPage: React.FC<{}> = ({}) => {

  const { user } = useAuth()

  if (user === null) {
    return <Redirect to="/"/>
  }

  return (
    <div> 
    <TopBar />
    <Box id="settings-wrapper" display="flex" flexDirection="column" alignItems="center">
      <Grid
          id="account"
          spacing={1}
          container
          mt={2}
          flexDirection="column"
          alignItems="flex-start"
          width="100%"
          maxWidth={MAX_PAGE_WIDTH}
          height="100%"
        >

        <ProfileSettings />

        <AccountSettings />

      </Grid>
    </Box>
    </div>
  );
}

export default SettingsPage;
