import { PaginatedResponse } from "../requests"
import { Follow, Prediction, User } from "../shared/data/types"

export const PAGE_SIZE = 10.0
export const INITIAL_STATE: State = {
    predictions: [],
    followers: [],
    follows: [],
    hasExpiredPredictions: false,
    currentPage: 1,
    numPages: 1,
    selectedPredictionTabIndex: 0,
    selectedFriendshipTabIndex: 0,
    totalPredictionCount: 0,
    numEvaluatedPredictions: 0,
    numCounterPredictions: 0,
    predictionsStale: true,
    followersStale: false,
    followsStale: false,
    showCreatePredictionModal: false,
    numFollowedBy: 0,
    numFollowing: 0,
    loggedInUserFollowsProfileUser: false,
    followRequestIsLoading: false,
    followRequestError: null,
    showPredictionTabs: true,
}

type SetPage = {
    type: "SET_PAGE"
    newPage: number
}
type SetSelectedPredictionTabIndex = {
    type: "SET_SELECTED_PREDICTION_TAB_INDEX"
    newSelectedPredictionTabIndex: number
}
type SetSelectedFriendshipTabIndex = {
    type: "SET_SELECTED_FRIENDSHIP_TAB_INDEX"
    newSelectedFriendshipTabIndex: number
}
type SetPredictions = {
    type: "SET_PREDICTIONS"
    data: PaginatedResponse<Prediction>
}
type SetFollowers = {
    type: "SET_FOLLOWERS"
    data: PaginatedResponse<Follow>
}
type SetFollows = {
    type: "SET_FOLLOWS"
    data: PaginatedResponse<Follow>
}
type CreatePrediction = {
    type: "CREATE_PREDICTION"
}
type SetExpired = {
    type: "SET_EXPIRED"
    hasExpired: boolean
}
type SetNumTotalPredictions = {
    type: "SET_NUM_TOTAL_PREDICTIONS"
    count: number
}
type SetNumEvaluatedPredictions = {
    type: "SET_NUM_EVALUATED_PREDICTIONS"
    count: number
}
type SetNumCounterPredictions = {
    type: "SET_NUM_COUNTER_PREDICTIONS"
    count: number
}
type ShowCreatePredictionModal = {
    type: "SHOW_CREATE_PREDICTION_MODAL"
    show: boolean
}
type SetNumFollowing =  {
    type: "SET_NUM_FOLLOWING"
    count: number
}
type SetNumFollowedBy = {
    type: "SET_NUM_FOLLOWED_BY"
    count: number
}
type SetLoggedInUserFollowsProfileUser = {
    type: "SET_LOGGED_IN_USER_FOLLOWS_PROFILE_USER"
    condition: boolean
}
type FollowRequest = {
    type: "FOLLOW_REQUEST"
}
type FollowRequestSuccess = {
    type: "FOLLOW_REQUEST_SUCCESS"
    /** if true, then it was a follow request. if false then it was an unfollow request */
    followed: boolean
}
type FollowRequestError = {
    type: "FOLLOW_REQUEST_ERROR"
    error: string
}
type TogglePredictionsTab = {
    type: "TOGGLE_PREDICTIONS_TAB"
    /**
     * The selected tab upon switching to the followers/following tabs depends on whether the "x followers"
     * or "y following" link was clicked to get there. Hence why we add a parameter to this action to tell
     * us which one to switch to. This isn't the same as switching to the prediction tabs because we display
     * a single "x predictions" link, so we always switch back to the first index.
     */
    newSelectedFriendshipTabIndex?: number
}
type ResetState = {
    type: "RESET_STATE"
}

type State = {
    /**
     * Predictions being displayed for the current tab
     */
    predictions: Array<Prediction>

    /**
     * Users who are following the profile user
     */
    followers: Array<User>

    /**
     * Users that the profile user is following
     */
    follows: Array<User>

    /**
     * Set to true when the user has at least one unevaluated prediction past
     * its evaluation deadline
     */
    hasExpiredPredictions: boolean

    /**
     * The page number of the tab under view. Indexing starts at 1
     */
    currentPage: number

    /**
     * The total number of pages for the tab under view.
     */
    numPages: number

    /**
     * The index of the prediction tab under view (relevant only when showPredictionTabs === true).
     * See INDEX_TO_PREDICTION_ORDERING for how the indices map to prediction orderings.
     */
    selectedPredictionTabIndex: number

    /**
     * Index of the follower tab. 0 === Followers, 1 === Following
     * (relevant only whne showPredictionTabs === false)
     */
    selectedFriendshipTabIndex: number

    /**
     * Total number of predictions made by this user (irrespective of ordering)
     */
    totalPredictionCount: number

    /**
     * The total number of predictions this user has evaluated
     */
    numEvaluatedPredictions: number

    /**
     * The total number of counter predictions made by this user
     */
    numCounterPredictions: number

    /**
     * Triggers a refresh on prediction data when set to true. Refresh is needed
     * for a tab change, tab index change, creating a new prediction, or tab section change
     */
    predictionsStale: boolean

    /**
     * Triggers a refresh on followers data when set to true. Refresh is needed
     * for a tab change, tab index change, or tab section change
     */
    followersStale: boolean

    /**
     * Triggers a refresh on follow data when set to true. Refresh is needed
     * for a tab change, tab index change, or tab section change
     */
    followsStale: boolean

    /**
     * CreatePredictionModal is displayed when this is set to true
     */
    showCreatePredictionModal: boolean

    /**
     * Number of users that this user is following
     */
    numFollowing: number

    /**
     * Number of users who follow this user
     */
    numFollowedBy: number

    /**
     * When true the logged in user follows the profile user. When false the logged
     * in user doesn't follow the profile user OR their is no logged in user
     */
    loggedInUserFollowsProfileUser: boolean

    /**
     * True when either a follow or unfollow request is in flight
     */
    followRequestIsLoading: boolean

    /**
     * If non-null then an error occurred while fetching followers/follows
     */
    followRequestError: string | null

    /**
     * When true the prediction tabs are under display. When false the followers/following
     * tabs are under display.
     */
    showPredictionTabs: boolean
}

type Action =
    SetPage |
    SetSelectedPredictionTabIndex |
    SetSelectedFriendshipTabIndex |
    SetPredictions |
    SetFollowers |
    SetFollows |
    CreatePrediction |
    SetExpired |
    SetNumTotalPredictions |
    SetNumEvaluatedPredictions |
    SetNumCounterPredictions |
    ShowCreatePredictionModal |
    SetNumFollowedBy |
    SetNumFollowing |
    SetLoggedInUserFollowsProfileUser |
    FollowRequest |
    FollowRequestSuccess |
    FollowRequestError |
    TogglePredictionsTab |
    ResetState

/**
 * Update the Profile component's state based on an action
 */
const profileReducer = (state: State, action: Action): State => {
    switch (action.type) {
        case "SET_PAGE":
            // Page changes only occur within the same tab. We can use existing state to
            // determine which tab that is.
            if (state.showPredictionTabs) { // Predictions are stale - the component handles which prediction ordering the new page needs to be fetched for
                return {
                    ...state,
                    currentPage: action.newPage,
                    predictionsStale: true,
                }
            } else if (state.selectedFriendshipTabIndex === 0) { // Followers are stale
                return {
                    ...state,
                    currentPage: action.newPage,
                    followersStale: true,
                }
            } else if (state.selectedFriendshipTabIndex === 1) { // Follows are stale
                return {
                    ...state,
                    currentPage: action.newPage,
                    followsStale: true
                }
            } else {
                console.error(`Unexpected state for SET_PAGE - state=${JSON.stringify(state)}, action=${JSON.stringify(action)}`)
                return state;
            }
        case "SET_SELECTED_PREDICTION_TAB_INDEX":
            if (action.newSelectedPredictionTabIndex !== state.selectedPredictionTabIndex) {
                return {
                    ...state,
                    selectedPredictionTabIndex: action.newSelectedPredictionTabIndex,
                    currentPage: 1,
                    predictionsStale: true
                }
            } else {
                return state
            }
        case "SET_SELECTED_FRIENDSHIP_TAB_INDEX":
            return {
                ...state,
                selectedFriendshipTabIndex: action.newSelectedFriendshipTabIndex,
                currentPage: 1,
                followersStale: action.newSelectedFriendshipTabIndex === 0,
                followsStale: action.newSelectedFriendshipTabIndex === 1,
            }
        case "SET_PREDICTIONS":
            return {
                ...state,
                numPages: Math.ceil(action.data.count / PAGE_SIZE),
                predictions: action.data.results,
                predictionsStale: false
            }
        case "SET_FOLLOWERS":
            return {
                ...state,
                numPages: Math.ceil(action.data.count / PAGE_SIZE),
                followers: action.data.results.map(follow => follow.follower),
                followersStale: false,
            }
        case "SET_FOLLOWS":
            return {
                ...state,
                numPages: Math.ceil(action.data.count / PAGE_SIZE),
                follows: action.data.results.map(follow => follow.user),
                followsStale: false,
            }
        case "CREATE_PREDICTION":
            return {
                ...state,
                selectedPredictionTabIndex: 0,
                currentPage: 1,
                predictionsStale: true,
                showCreatePredictionModal: false,
            }
        case "SET_EXPIRED":
            return {
                ...state,
                hasExpiredPredictions: action.hasExpired
            }
        case "SET_NUM_TOTAL_PREDICTIONS":
            return {
                ...state,
                totalPredictionCount: action.count
            }
        case "SET_NUM_EVALUATED_PREDICTIONS":
            return {
                ...state,
                numEvaluatedPredictions: action.count
            }
        case "SET_NUM_COUNTER_PREDICTIONS":
            return {
                ...state,
                numCounterPredictions: action.count
            }
        case "SHOW_CREATE_PREDICTION_MODAL":
            return {
                ...state,
                showCreatePredictionModal: action.show
            }
        case "SET_NUM_FOLLOWING":
            return {
                ...state,
                numFollowing: action.count
            }
        case "SET_NUM_FOLLOWED_BY":
            return {
                ...state,
                numFollowedBy: action.count
            }
        case "SET_LOGGED_IN_USER_FOLLOWS_PROFILE_USER":
            return {
                ...state,
                loggedInUserFollowsProfileUser: action.condition
            }
        case "FOLLOW_REQUEST":
            return {
                ...state,
                followRequestIsLoading: true,
                followRequestError: null,
            }
        case "FOLLOW_REQUEST_SUCCESS":
            // if it was a follow action, then increment the follower count by 1
            // if it was an unfollow action, then decrement the follower count by 1
            // we do this to udpate the front-end without having to make another api
            // request for the number of followers
            return {
                ...state,
                followRequestIsLoading: false,
                followRequestError: null,
                loggedInUserFollowsProfileUser: action.followed,
                numFollowedBy: action.followed ? state.numFollowedBy + 1 : state.numFollowedBy - 1
            }
        case "FOLLOW_REQUEST_ERROR":
            return {
                ...state,
                followRequestIsLoading: false,
                followRequestError: action.error
            }
        case "TOGGLE_PREDICTIONS_TAB":
            const switchingToPredictions = !state.showPredictionTabs
            if (switchingToPredictions) {
                return {
                    ...state,
                    showPredictionTabs: true,
                    predictionsStale: true,
                    selectedPredictionTabIndex: 0,
                }
            } else if (typeof action.newSelectedFriendshipTabIndex === "number") {
                return {
                    ...state,
                    showPredictionTabs: false,
                    selectedFriendshipTabIndex: action.newSelectedFriendshipTabIndex,
                    followersStale: action.newSelectedFriendshipTabIndex === 0,
                    followsStale: action.newSelectedFriendshipTabIndex === 1,
                }
            } else {
                console.error(`Unexpected state for TOGGLE_PREDICTIONS_TAB - state=${JSON.stringify(state)}, action=${JSON.stringify(action)}`)
                return state
            }
        case "RESET_STATE":
            return INITIAL_STATE
    }
}

export default profileReducer