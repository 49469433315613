import React, { useEffect, useReducer, useRef, useState } from "react";
import {
    Grid,
    Tabs,
    Tab,
    Box,
    Button,
    List,
    ListItem,
    ListItemButton,
    Pagination,
    Alert,
    Divider,
    Typography,
    Chip,
    useMediaQuery,
    useTheme,
    Link
} from "@mui/material"
import { useParams, useHistory } from "react-router";
import { SERVER_ADDRESS } from "../constants";
import { User } from "../shared/data/types";
import PredictionRow from "./PredictionRow";
import CreatePredictionModal from "../modals/CreatePredictionModal";
import { fetchFollowers, fetchFollowRelationship, fetchFollows, fetchPredictions, follow, Ordering, unfollow } from "../requests";
import { getExpiredPredictionsAlertMessage } from "./profileUtils";
import TopBar from "../TopBar";
import { useAuth } from "../account/AuthProvider";
import profileReducer, { INITIAL_STATE, PAGE_SIZE } from "./profileReducer";
import { CircularProgress } from "@material-ui/core";
import ProfileContentRow from "./ProfileContentRow";

const INDEX_TO_PREDICTION_ORDERING: Record<number, Ordering> = {
    0: "RECENTLY_MADE",
    1: "RECENTLY_EVALUATED",
    2: "EVALUATED_SOON",
    3: "COUNTER_PREDICTIONS",
}

const TabLabel: React.FC<{ labelName: string, labelCount: string | number, disabled: boolean }> =
({
    labelName,
    labelCount,
    // The chip doesn't automatically disable when the parent Tab is disabled.
    // So we need to set it manually
    disabled
}) => {
    return (
        <Box>
            <Typography fontSize="small">
                {labelName} &nbsp; <Chip disabled={disabled} label={labelCount} />
            </Typography> 
        </Box>
    )
}

const Profile: React.FC<{}> = ({}) => {

    const history = useHistory()

    const { user } = useAuth()
    const { username } = useParams<{ username: string | undefined}>()
    const [profile, setProfile] = useState<User | null>(null)

    const theme = useTheme()
    const isWiderThanSmBreakpoint = useMediaQuery(theme.breakpoints.up('sm'));
    const maxWidth = isWiderThanSmBreakpoint ? "70%" : "100%";

    const [{
        predictions,
        followers,
        follows,
        hasExpiredPredictions,
        currentPage,
        numPages,
        selectedPredictionTabIndex,
        selectedFriendshipTabIndex,
        totalPredictionCount,
        numEvaluatedPredictions,
        numCounterPredictions,
        predictionsStale,
        followersStale,
        followsStale,
        showCreatePredictionModal,
        numFollowing,
        numFollowedBy,
        loggedInUserFollowsProfileUser,
        followRequestIsLoading,
        followRequestError,
        showPredictionTabs,
    }, dispatch] = useReducer(profileReducer, INITIAL_STATE)

    const onFollowOrUnfollow = () => {
        if (username && user) {
            dispatch({ type: "FOLLOW_REQUEST" })
            if (loggedInUserFollowsProfileUser) {
                // The user follows the profile - so unfollow it
                unfollow(user.username, username)
                .then(response => {
                    if (response.status === 204) {
                        dispatch({
                            type: "FOLLOW_REQUEST_SUCCESS",
                            followed: false
                        })
                    }
                }).catch(error => dispatch({ type: "FOLLOW_REQUEST_ERROR", error: error }))
            } else {
                // The user does not follow the profile - so follow it
                follow(username)
                .then(response => {
                    if (response.status === 201) {
                        dispatch({
                            type: "FOLLOW_REQUEST_SUCCESS",
                            followed: true
                        })
                    }
                }).catch(error => dispatch({ type: "FOLLOW_REQUEST_ERROR", error: error }))
            }
        }
    }

    /**
     * Fetch profile info
     */
    useEffect(() => {
        fetch(`${SERVER_ADDRESS}/users/profiles/${username}/`)
          .then(response => response.json())
          .then(data => setProfile(data));
    }, [username])

    /**
     * Fetch expired predictions. If the user has expired predictions then we disable the new
     * prediction button and display a warning that they must evaluate their expired predictions
     * before creating new ones
     */
    useEffect(() => {
        if (username) {
            fetchPredictions(username!, "EVALUATED_SOON", PAGE_SIZE, 0)
            .then((response) => {
                const hasExpiredPredictions = response.data.results.filter((prediction) => {
                    return prediction.evaluation === null && new Date(prediction.evaluate_at) < new Date()
                }).length > 0
                dispatch({ type: "SET_EXPIRED", hasExpired: hasExpiredPredictions })
            }) 
        }
    }, [username])

    /**
     * When we hit the back button from one profile to another, we need to clear the state.
     * Otherwise we would display prediction data of the previous profile for the current profile.
     */
    useEffect(() => {
        window.onpopstate = () => {
            dispatch({ type: "RESET_STATE" })
        }
    }, [])

    /**
     * Fetch the current page of predictions for the currently selected prediction ordering
     * and page number
     */
    useEffect(() => {
        if (predictionsStale && username) {
            const offset = (currentPage - 1) * PAGE_SIZE;
            fetchPredictions(username, INDEX_TO_PREDICTION_ORDERING[selectedPredictionTabIndex], PAGE_SIZE, offset)
            .then(response => dispatch({ type: "SET_PREDICTIONS", data: response.data}))
        }
    }, [username, selectedPredictionTabIndex, predictionsStale])

    /**
     * Fetch the current page of followers 
     */
    useEffect(() => {
        if (username && !showPredictionTabs && selectedFriendshipTabIndex === 0 && followersStale) {
            const offset = (currentPage - 1) * PAGE_SIZE;
            fetchFollowers(username, PAGE_SIZE, offset)
            .then(response => {
                dispatch({ type: "SET_FOLLOWERS", data: response.data })
            })
        }
    }, [username, showPredictionTabs, selectedFriendshipTabIndex, followersStale])

    /**
     * Fetch the current page of people this user is following
     */
    useEffect(() => {
        if (username && !showPredictionTabs && selectedFriendshipTabIndex === 1 && followsStale) {
            const offset = (currentPage - 1) * PAGE_SIZE;
            fetchFollows(username, PAGE_SIZE, offset)
            .then(response => {
                dispatch({ type: "SET_FOLLOWS", data: response.data })
            })
        }
    }, [username, showPredictionTabs, selectedFriendshipTabIndex, followsStale])

    /**
     * Fetch the count of predictions across all orderings
     */
    useEffect(() => {
        if (username && predictionsStale) {
            fetchPredictions(username, undefined, 1, 0)
            .then(response => dispatch({ type: "SET_NUM_TOTAL_PREDICTIONS", count: response.data.count}))
        }
    }, [username, predictionsStale])

    /**
     * Fetch the count of evaluated predictions
     */
    useEffect(() => {
        if (username && predictionsStale) {
            fetchPredictions(username, "RECENTLY_EVALUATED", 1, 0)
            .then(response => dispatch({ type: "SET_NUM_EVALUATED_PREDICTIONS", count: response.data.count }))
        }
    }, [username, predictionsStale])

    /**
     * Fetch the count of counter predictions
     */
    useEffect(() => {
        if (username && predictionsStale) {
            fetchPredictions(username, "COUNTER_PREDICTIONS", 1, 0)
            .then(response =>  dispatch({ type: "SET_NUM_COUNTER_PREDICTIONS", count: response.data.count }))
        }
    }, [username, predictionsStale])

    /**
     * Fetch the count of users who follow this user
     */
    useEffect(() => {
        if (username) {
            fetchFollowers(username, 1, 0)
            .then(response => dispatch({ type: "SET_NUM_FOLLOWED_BY", count: response.data.count }))
        }
    }, [username])

    /**
     * Fetch the counter of users this user is following
     */
    useEffect(() => {
        if (username) {
            fetchFollows(username, 1, 0)
            .then(response => dispatch({ type: "SET_NUM_FOLLOWING", count: response.data.count }))
        }
    }, [username])

    /**
     * Check the follow relationship between the logged in user and the user who owns the profile
     */
    useEffect(() => {
        if (username && user) {
            fetchFollowRelationship(user.username, username)
            .then(response => {
                if (response.status === 200) {
                    dispatch({ type: "SET_LOGGED_IN_USER_FOLLOWS_PROFILE_USER", condition: true })
                }
            }).catch((error) => {
                if (error.response?.status === 404) {
                    dispatch({ type: "SET_LOGGED_IN_USER_FOLLOWS_PROFILE_USER", condition: false })
                }
            })
        }
    }, [username, user])

    if (!profile || !username) {
        return <div>Loading</div>
    }

    const loggedInUserIsProfileOwner = user !== null && user.username === username
    const loggedInUserIsNotProfileOwner = user !== null && user.username !== username

    return (
      <div>
        <TopBar />
        <Box id="profile-wrapper" display="flex" flexDirection="column" alignItems="center">
            <Grid 
                id="profile"
                flexDirection="column"
                width="100%"
                maxWidth={maxWidth}
                height="100%">
                
                <Grid item style={{ padding: "8px" }}>
                    <ProfileContentRow
                        user={profile}
                        photoSize="75px"
                        name={<Typography style={{fontWeight: 600}} mt="10px" mb="-5px" variant="h5" color="textPrimary">{profile.name}</Typography>}
                        username={<Typography gutterBottom variant="subtitle2" color="textSecondary">@{profile.username}</Typography>}
                    >
                        {profile.bio ? <Typography>{profile.bio}</Typography> : null}
                    </ProfileContentRow>
                </Grid>

                <Grid item mt={1} mb={1}/>

                <Grid item container mb={1} style={{ paddingLeft: "8px" }}>
                    {showPredictionTabs ? (
                        <>
                        <Link
                            sx={{ cursor: "pointer" }}
                            underline="hover"
                            onClick={() => dispatch({ type: "TOGGLE_PREDICTIONS_TAB", newSelectedFriendshipTabIndex: 0 })}
                        >
                            <Typography>
                                <b>{numFollowedBy}</b> {numFollowedBy === 1 ? "follower" : "followers"}
                            </Typography>
                        </Link>
                        <Typography>&nbsp;&nbsp;</Typography>
                        <Link
                            sx={{ cursor: "pointer" }}
                            underline="hover"
                            onClick={() => dispatch({ type: "TOGGLE_PREDICTIONS_TAB", newSelectedFriendshipTabIndex: 1 })}
                        >
                            <Typography>
                                <b>{numFollowing}</b> following
                            </Typography>
                        </Link>
                        </>
                    ) : (
                        <Link sx={{ cursor: "pointer" }} underline="hover" onClick={() => dispatch({ type: "TOGGLE_PREDICTIONS_TAB" })}>
                            <Typography>
                                <b>{totalPredictionCount}</b> predictions
                            </Typography>
                        </Link>
                    )}
                </Grid>

                {/* TODO - consider showing the follow button even when the user logged out.
                Then when they click Follow we will present them with the signup modal */}
                {loggedInUserIsNotProfileOwner && (
                    <Grid item>
                        <Button variant="contained" onClick={onFollowOrUnfollow}>
                            {followRequestIsLoading ? (
                                <CircularProgress size={20}/>
                            ) : (
                                loggedInUserFollowsProfileUser ? "Unfollow" : "Follow"
                            )}
                        </Button>
                    </Grid>
                )}

                {loggedInUserIsProfileOwner && (
                    <Grid item>
                        <Button
                            disabled={hasExpiredPredictions === undefined || hasExpiredPredictions}
                            variant="contained"
                            onClick={() => dispatch({ type: "SHOW_CREATE_PREDICTION_MODAL", show: true})}>
                            New Prediction
                        </Button>
                        <CreatePredictionModal
                            open={showCreatePredictionModal}
                            onClose={() => dispatch({ type: "SHOW_CREATE_PREDICTION_MODAL", show: false})}
                            onSubmitSuccess={() => dispatch({ type: "CREATE_PREDICTION" })}
                            originalPrediction={null}
                        />
                    </Grid>
                )}

                {hasExpiredPredictions !== undefined && hasExpiredPredictions && (
                    <Grid item mt={1}>
                        <Alert severity="error">
                            {getExpiredPredictionsAlertMessage(username, loggedInUserIsProfileOwner)}
                        </Alert>
                    </Grid>
                )}

                <Grid item mt={1}>
                    <Divider />
                </Grid>

                <Grid item>
                    <Box display="flex" alignContent="center">
                        {showPredictionTabs ? (
                            <Tabs
                                variant="scrollable"
                                value={selectedPredictionTabIndex}
                                onChange={(event, newValue) => dispatch({ type: "SET_SELECTED_PREDICTION_TAB_INDEX",  newSelectedPredictionTabIndex: newValue })}
                            >
                                <Tab label={
                                    <TabLabel
                                        disabled={false}
                                        labelName="All"
                                        labelCount={totalPredictionCount}
                                    />
                                }/>
                                <Tab disabled={numEvaluatedPredictions === 0} label={
                                    <TabLabel
                                        disabled={numEvaluatedPredictions === 0}
                                        labelName="Evaluated"
                                        labelCount={numEvaluatedPredictions === 0 ? "0" : numEvaluatedPredictions}
                                    />
                                }/>
                                <Tab disabled={totalPredictionCount === 0 || totalPredictionCount - numEvaluatedPredictions === 0} label={
                                    <TabLabel
                                        disabled={totalPredictionCount === 0 || totalPredictionCount - numEvaluatedPredictions === 0 }
                                        labelName="Unevaluated"
                                        labelCount={totalPredictionCount - numEvaluatedPredictions === 0 ? "0" : totalPredictionCount - numEvaluatedPredictions}
                                    />
                                }/>
                                <Tab disabled={numCounterPredictions === undefined || numCounterPredictions === 0}
                                    label={
                                        <TabLabel
                                            disabled={numCounterPredictions === undefined || numCounterPredictions === 0}
                                            labelName="Counter predictions"
                                            labelCount={numCounterPredictions || 0}
                                        />
                                    }
                                />
                            </Tabs>
                        ) : (
                            <Tabs
                                value={selectedFriendshipTabIndex}
                                variant="scrollable"
                                onChange={(event, newValue) => dispatch({ type: "SET_SELECTED_FRIENDSHIP_TAB_INDEX", newSelectedFriendshipTabIndex: newValue })}>
                                <Tab label={<TabLabel labelName="Followers" disabled={false} labelCount={numFollowedBy} />} />
                                <Tab label={<TabLabel labelName="Following" disabled={false} labelCount={numFollowing} />} />
                            </Tabs>
                        )}
                    </Box>
                </Grid>

                <Grid item overflow="scroll">
                    <List disablePadding >
                        {showPredictionTabs ? (
                            predictions.map((prediction) => {
                                return (
                                    <ListItem key={prediction.id} divider disablePadding>
                                        <ListItemButton onClick={() => history.push(`/predictions/${prediction.id}`)}>
                                            <PredictionRow showTitle={false} counterPredictionPreviewClickable={false} prediction={prediction} />
                                        </ListItemButton>
                                    </ListItem>
                                )
                            })
                        ) : (
                            selectedFriendshipTabIndex === 0 ? (
                                followers.map(user => (
                                    <ListItem key={`followers-${user.username}`} divider disablePadding>
                                        <ListItemButton onClick={() => {
                                            history.push(`/user/${user.username}`)
                                            dispatch({ type: "RESET_STATE" })
                                        }}>
                                            <ProfileContentRow user={user} photoSize="50px">
                                                <Typography>{user.bio}</Typography>
                                            </ProfileContentRow>
                                        </ListItemButton>
                                    </ListItem>
                                ))
                            ) : (
                                follows.map(user => (
                                    <ListItem key={`following-${user.username}`} divider disablePadding>
                                        <ListItemButton onClick={() => {
                                            history.push(`/user/${user.username}`)
                                            dispatch({ type: "RESET_STATE" })
                                        }}>
                                            <ProfileContentRow user={user} photoSize="50px">
                                                <Typography>{user.bio}</Typography>
                                            </ProfileContentRow>
                                        </ListItemButton>
                                    </ListItem>
                                ))
                            )
                        )}
                    </List>
                </Grid>

                {numPages > 1 && (
                    <Grid item>
                        <Pagination count={numPages} page={currentPage} onChange={(e, page) => dispatch({type: "SET_PAGE", newPage: page})}/>
                    </Grid>
                )}

            </Grid>
        </Box>
        </div>
    )
}

export default Profile
