import { Helmet } from "react-helmet"

const RootMeta = () => {

  return (
    <Helmet>
      {/* Generate these meta tags here: https://www.heymeta.com/url/4sit3.com */}

      {/* HTML Meta Tags */}
      <title>Foresight</title>
      <meta name="description" content="A public prediction ledger. Assess the credibility of others. Create predictions directly from tweets. Free for everyone." />

      {/* Google / Search Engine Tags */}
      <meta itemProp="name" content="Foresight" />
      <meta itemProp="description" content="A public prediction ledger. Assess the credibility of others. Create predictions directly from tweets. Free for everyone." />
      <meta itemProp="image" content="https://4sit3.com/favicon.ico" />

      {/* Facebook Meta Tags */}
      <meta property="og:url" content="https://4sit3.com" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Foresight" />
      <meta property="og:description" content="A public prediction ledger. Assess the credibility of others. Create predictions directly from tweets. Free for everyone." />
      <meta property="og:image" content="https://4sit3.com/favicon.ico" />

      {/* Twitter Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Foresight" />
      <meta name="twitter:description" content="A public prediction ledger. Assess the credibility of others. Create predictions directly from tweets. Free for everyone." />
      <meta name="twitter:image" content="https://4sit3.com/favicon.ico" />
    </Helmet>
  )
}

export default RootMeta
