import { Grid, Box, Divider, Typography, useMediaQuery, useTheme } from "@mui/material"
import { useParams } from "react-router";
import React, { useEffect, useState } from "react";
import { Prediction } from "../shared/data/types";
import ProfileRow from "../profile/PredictionRow";
import CounterPredictionSection from "../prediction-detail-page/CounterPredictionSection"
import EvaluationRow from "./EvaluationRow"
import { getPrediction, viewPrediction } from "../requests";
import TopBar from "../TopBar";
import { Helmet } from "react-helmet";
import ProfileContentRow from "../profile/ProfileContentRow";

type Props = {}

const PredictionDetailPage: React.FC<Props> = ({}) => {
    let { id } = useParams<{ id: string | undefined }>();

    const [prediction, setPrediction] = useState<Prediction | null>(null);

    // Fetch the prediction
    useEffect(() => {
        if (id) {
            getPrediction(id)
            .then((response) => {
                setPrediction(response.data)
            })
        }
    }, [id])

    const theme = useTheme()
    const isWiderThanSmBreakpoint = useMediaQuery(theme.breakpoints.up('sm'));
    const maxWidth = isWiderThanSmBreakpoint ? "70%" : "100%";

    const hasViewedPrediction = (id: string) => {
      let storage = window.sessionStorage
      let isInStorage = storage.getItem(`prediction-views:${id}`);
      return !!isInStorage
    }

    const markPredictionAsViewedLocally = (id: string) => {
      let storage = window.sessionStorage
      storage.setItem(`prediction-views:${id}`, "y")
    }

    // Mark the prediction as viewed
    useEffect(() => {
        if (id && !hasViewedPrediction(id)) {
          viewPrediction(Number(id))
          markPredictionAsViewedLocally(id)
        }
    }, [id])

    if (!id) {
        return <div>Error</div>
    }

    if (!prediction) {
        return <div>Loading</div>
    }

    return (
        <div>
        <Helmet>
          {/* Twitter Meta Tags */}
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:title" content="Prediction" />
          <meta name="twitter:description" content={prediction.description}/>
        </Helmet>
        <TopBar />

        <Box id="prediction-detail-wrapper" display="flex" flexDirection="column" alignItems="center">
            <Grid
                id="prediction-detail-container"
                spacing={1}
                container
                maxWidth={maxWidth}
                width="100%"
                height="100%"
                style={{ padding: "8px"}}>

                <ProfileContentRow
                    user={prediction.user}
                    photoSize="75px"
                    name={<Typography style={{fontWeight: 600}} mt="10px" mb="-5px" variant="h5" color="textPrimary">{prediction.user.name}</Typography>}
                    username={<Typography gutterBottom variant="subtitle2" color="textSecondary">@{prediction.user.username}</Typography>}
                    linkToProfile
                >
                    {prediction.user.bio ? <Typography>{prediction.user.bio}</Typography> : null}
                </ProfileContentRow>

                <Divider sx={{width: "100%", marginTop: "10px", marginBottom: "10px"}}/>

                <Box style={{width: "100%", marginTop: "10px"}}>
                    <ProfileRow showTitle={true} counterPredictionPreviewClickable={true} prediction={prediction} />
                </Box>

                <Divider sx={{width: "100%", marginTop: "10px", marginBottom: "10px"}} />

                <EvaluationRow
                    prediction={prediction}
                    onSubmitEvaluationSuccess={(evaluation) => {
                        setPrediction({
                            ...prediction,
                            evaluation: evaluation,
                        })
                    }}
                />

                <Divider sx={{width: "100%", marginTop: "10px", marginBottom: "10px"}}/>

                {/* You cannot counter a counter prediction */}
                {prediction.original_prediction === null && <CounterPredictionSection prediction={prediction} />}
            </Grid>
        </Box>
        </div>
    )
}

export default PredictionDetailPage;
