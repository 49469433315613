import { ChangeEvent, useState } from "react"
import {
    Grid,
    Typography,
    Button,
    Box,
    TextField,
    Alert,
    CircularProgress
} from "@mui/material"
import { User } from "../shared/data/types"
import { DEFAUlT_IMAGE } from "../constants"
import { useAuth } from "./AuthProvider"

type FormState = "Entry" | "Submitting"

const MAX_LENGTH_NAME = 64
const MAX_LENGTH_BIO = 160

const ProfileSettings: React.FC<{}> = ({}) => {

    const { user, updateProfile } = useAuth()

    const [submitted, setSubmitted] = useState(false)

    const [formState, setFormState] = useState<FormState>("Entry")

    const [name, setName] = useState(user?.name)
    const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.value.length <= MAX_LENGTH_NAME) {
        setName(e.target.value)
      }
    }
  
    const [bioInputIsFocused, setBioInputIsFocused] = useState(false)
    const [bio, setBio] = useState(user?.bio)
    const onBioChange = (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.value.length <= MAX_LENGTH_BIO) {
        setBio(e.target.value)
      }
    }
  
    const [newPhoto, setNewPhoto] = useState<string | null>(null)
    const [newPhotoFile, setNewPhotoFile] = useState<File | null>(null)
    const onPhotoChange = (e: ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.item(0)
      if (file) {
        setNewPhotoFile(file)
        const fileReader = new FileReader()
        fileReader.onload = (event) => {
          if (event.target && typeof(event.target.result) === "string") {
            setNewPhoto(event.target.result)
          }
        }
        fileReader.readAsDataURL(file)
      }
    }


    const onUpdateProfile = () => {
        setSubmitted(false)
        setFormState("Submitting")

        const updatedProfile = {
          bio,
          name
        }

        updateProfile(updatedProfile, (user: User) => {
          if (newPhotoFile) {
            setNewPhoto(null)
            setNewPhotoFile(null)
          }
          setSubmitted(true)
          setFormState("Entry")
        }, (error: any) => {
          // TODO - error handling
          setFormState("Entry")
        }, newPhotoFile ? newPhotoFile : undefined)
    }

    const isFormDirty = () => {
        return name !== user?.name || bio != user?.bio || newPhoto != null
    }

    let imgSrc;
    if (user?.profile_photo_url && newPhoto === null) {
      imgSrc = user?.profile_photo_url
    } else if (newPhoto !== null) {
      imgSrc = newPhoto
    } else {
      imgSrc = DEFAUlT_IMAGE
    }

    // 1. Bio is empty, unfocused. Should just say bio
    // 2. Bio is empty, focused. Should say bio (0 / 160)
    // 2. Bio is not empty. Should say Bio (x/160)
    let bioInputLabel;
    if (!bioInputIsFocused && (!bio || bio.length === 0)) {
        bioInputLabel = "Bio"
    } else {
        const bioLength = bio ? bio.length : 0
        bioInputLabel = `Bio (${bioLength}/${MAX_LENGTH_BIO})`
    }

    const saveProfileLabel = formState === "Entry" ? "Save Profile" : <CircularProgress size={20} />

    return (
        <>
        <Grid item>
            <Typography variant="h4" color="textPrimary" align="center" style={{width: "100%"}} gutterBottom>
                Profile Settings
              </Typography>
          </Grid>
          <Grid id="avatar" item>
              <Box display="flex" flexDirection="column" alignItems="center">
                <img src={imgSrc} style={{maxWidth: "150px", maxHeight: "150px", borderRadius: "50%"}}/>
                <Button variant="contained" component="label" style={{marginTop: "10px"}}>
                  <Typography fontSize="small">
                    Upload profile photo
                  </Typography>
                  <input
                    accept="image/*"
                    style={{ display: 'none' }}
                    id="raised-button-file"
                    type="file"
                    onChange={onPhotoChange}
                  />
                </Button>
              </Box>
          </Grid>

          <Grid item>
            <TextField
                id="name"
                label="Name"
                variant="outlined"
                margin="normal"
                value={name}
                defaultValue={user?.name}
                onChange={onNameChange}
            /> 
          </Grid>

          <Grid item width="100%">
            <TextField
                id="bio"
                fullWidth
                variant="outlined"
                margin="normal"
                label={bioInputLabel}
                value={bio}
                defaultValue={user?.bio}
                onChange={onBioChange}
                onFocus={() => setBioInputIsFocused(true)}
                onBlur={() => setBioInputIsFocused(false)}
                multiline
                minRows={5}
            /> 
          </Grid>
          
          {submitted && (
            <Grid>
              <Alert severity="success">
                <Typography>
                  Profile saved successfully
                </Typography>
              </Alert>
            </Grid>
          )}

          <Grid item>
            <Button
              type="submit"
              variant="contained"
              disabled={!isFormDirty() || formState === "Submitting"}
              onClick={onUpdateProfile}>
              {saveProfileLabel}
            </Button>
        </Grid>
        </>
    )
}

export default ProfileSettings
