import { Tooltip, IconButton, Typography } from "@mui/material"
import InfoIcon from "@mui/icons-material/Info"

type Props = {
    text: string
};

const InfoTooltip: React.FC<Props> = ({ text }) => {
    return (
        <Tooltip enterTouchDelay={50} title={<Typography paragraph textAlign="justify">{text}</Typography>}>
            <IconButton >
                <InfoIcon />
            </IconButton>
        </Tooltip>
    )
}

export default InfoTooltip
