import { ChangeEvent, FormEvent, useState } from "react";
import {
    Avatar,
    Button,
    FormControl,
    Grid,
    Link,
    Typography,
    TextField,
    Box
} from "@mui/material"
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import PasswordTextInput from "./PasswordTextInput";
import { useAuth } from '../account/AuthProvider';

type InputProps = {
    // Triggerred when the user clicks the "Don't have an account? Sign up instead" option
    onSignupInstead: () => void; 
}

type FormState = "Entry" | "Submitting" | "ForgotPassword";

const SignIn: React.FC<InputProps> = ({ onSignupInstead }) => {

    const { signIn } = useAuth()

    const [formData, setFormData] = useState({
        email: "",
        password: ""
    })

    const onEmailChange = (e: ChangeEvent<HTMLInputElement>) => setFormData({
        ...formData,
        email: e.target.value
    })

    const onPasswordChange = (password: string) => setFormData({
        ...formData,
        password: password
    }) 

    const [formError, setFormError] = useState("");

    const [formState, setFormState] = useState<FormState>("Entry");

    const onError = (error: any) => {
        console.log("error")
            if (error.response.status === 401) {
                console.log("401 error")
                setFormError("Invalid username or password");
            } else {
                setFormError("Something went wrong. Please try again later")
            }
        setFormState("Entry")
    }

    const handleOnSubmit = (event: FormEvent<HTMLInputElement>) => {
        event.preventDefault();
        setFormState("Submitting");

        signIn(
            formData.email,
            formData.password,
            (user) => { setFormState("Entry") },
            onError
        )
    }

    const textFieldCommonProps = {
        required: true,
        fullWidth: true,
        variant: "outlined" as const,
        margin: "normal" as const,
        disabled: formState === "Submitting" // During submit we disable data entry
    }

    // TODO - replace this manual password reset mechanism with an automatic one.
    if (formState === "ForgotPassword") {
        return (
            <Box>
                <Typography>
                    Please email <Link href="mailto:4sit3business@gmail.com">4sit3business@gmail.com</Link> with the
                    subject <b>Password Reset</b> to reset your password. We will reply shortly. Thank you!
                </Typography>
            </Box>
        )
    }

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            margin: 4
            }}>
            <Avatar>
                <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
                Sign in
            </Typography>
            <Box component="form" onSubmit={handleOnSubmit} display="flex" flexDirection="column" width="100%">
                <FormControl fullWidth variant="outlined">
                    <TextField
                        {...textFieldCommonProps}
                        id="email"
                        label="Email"
                        autoFocus
                        onChange={onEmailChange}
                    />
                    <PasswordTextInput
                        textFieldCommonProps={textFieldCommonProps}
                        onPasswordChange={onPasswordChange}
                        errorText={formError}
                        helperText={formError}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{mt: 3, mb: 2 }}
                    >
                        Sign In
                    </Button>
                </FormControl>
                <Grid container>
                    <Grid item xs={4} sm={4} md={4} textAlign="left">
                        {/* TODO - implement actual password recovery */}
                        <Link href="#" variant="body2" onClick={() => setFormState("ForgotPassword")}>
                            Forgot password?
                        </Link>
                    </Grid>
                    <Grid item xs={8} sm={8} md={8} textAlign="right">
                        <Link href="#" variant="body2" onClick={onSignupInstead}>
                            Don't have an account? Sign up
                        </Link>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default SignIn
