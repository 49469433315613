import { Button, Grid, Typography, List, ListItem, ListItemButton } from "@mui/material"
import { useEffect, useState } from "react"
import { useHistory } from "react-router"
import { useAuth } from "../account/AuthProvider"
import CreatePredictionModal from "../modals/CreatePredictionModal"
import ProfileContentRow from "../profile/ProfileContentRow"
import { getPredictionLikelihoodText, getPredictionStatusEmoji } from "../profile/profileUtils"
import { fetchCounterPredictions } from "../requests"
import InfoTooltip from "../shared/components/InfoTooltip"
import { Prediction } from "../shared/data/types"

type Props = {
    prediction: Prediction
}

const CounterPredictionSection: React.FC<Props> = ({ prediction }) => {

    const { user } = useAuth()
    const history = useHistory()
    const [counterPredictions, setCounterPredictions] = useState<Array<Prediction>>([])
    const [showNewCounterPredictionModal, setShowNewCounterPredictionModal] = useState(false)

    useEffect(() => {
        fetchCounterPredictions(prediction.id)
        .then((response) => {
            setCounterPredictions(response.data.results)
        })
    }, [prediction])

    const onCounterPredictionCreated = (prediction: Prediction) => {
        onCounterPredictionSelected(prediction.id)
    }

    const onCounterPredictionSelected = (counterPredictionId: number) => {
        history.push(`/predictions/${counterPredictionId}`)
    }

    const userOwnsPrediction = user?.username === prediction.user.username
    const alreadyEvaluated = prediction.evaluation != null

    return (
        <Grid container item flexDirection="column" alignItems="flex-start" style={{paddingLeft: 0}}>
            <Grid item>
                <Typography variant="h5" gutterBottom>Counter Predictions</Typography>
            </Grid>

            {counterPredictions.length === 0 && (
                <Typography>
                    This prediction has no counter predictions.
                </Typography>
            )}

            {/* Display button to create a counter prediction */}
            {!userOwnsPrediction && !alreadyEvaluated && user !== null && (
                <Grid item>
                    <Button variant="contained" onClick={() => { setShowNewCounterPredictionModal(true) }}>
                        <Typography fontSize="small">
                            Make Counter Prediction
                        </Typography>
                    </Button>
                    <InfoTooltip text={`Create a counter prediction if you disagree about the likelihood of ${prediction.user.name}'s prediction!`}/>
                    <CreatePredictionModal
                        open={showNewCounterPredictionModal}
                        onClose={() => setShowNewCounterPredictionModal(false)}
                        onSubmitSuccess={onCounterPredictionCreated}
                        originalPrediction={prediction}
                    />
                </Grid>
            )}

            <Grid id="counter-prediction-list-grid-item" item width="100%">
                <List >
                    {counterPredictions.map((counterPrediction) => {
                        return (
                            <ListItem key={counterPrediction.id} divider disablePadding>
                                <ListItemButton onClick={() => onCounterPredictionSelected(counterPrediction.id)}>
                                    <ProfileContentRow user={counterPrediction.user} photoSize="50px">
                                        <Grid flexDirection="column">
                                        <Typography variant="h5">{getPredictionStatusEmoji(counterPrediction)}{getPredictionLikelihoodText(counterPrediction)}%</Typography>
                                        <Typography>
                                            {counterPrediction.description}
                                        </Typography>
                                        </Grid>
                                    </ProfileContentRow>
                                </ListItemButton>
                            </ListItem>
                        )
                    })}
                </List>
            </Grid>
        </Grid>
    )


}

export default CounterPredictionSection
