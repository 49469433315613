import { Grid, Link, Typography } from "@mui/material"
import { DEFAUlT_IMAGE } from "../constants"
import { User } from "../shared/data/types"

type Props = {
    user: User
    name?: JSX.Element
    username?: JSX.Element
    photoSize?: string
    linkToProfile?: boolean
}

/**
 * Shared component to display a user's profile photo, name, and username with
 * some additional content appearing below (passed as children)
 * 
 * @param user the user to display the row for
 * @param name optional JSX element to display the user's name. Defaults to a bold typography component.
 * @param username optional JSX element to display the user's username. Defaults to a normal typography component.
 * @param photoSize optional size in pixels of the profile photo. Defaults to 50px.
 * @param linkToProfile when set to true then the profile photo, name, and username will navigate to the profile page when clicked
 */
const ProfileContentRow: React.FC<Props> = ({ user, name, username, photoSize, linkToProfile, children }) => {
    const profileImageUrl = user.profile_photo_url ? user.profile_photo_url : DEFAUlT_IMAGE
    const profileImageSize = photoSize ? photoSize : "50px"
    const profileUrl = `/user/${user.username}`

    let profileImageComponent = (
        <img
            src={profileImageUrl}
            style={{ maxWidth: profileImageSize, maxHeight: profileImageSize, borderRadius: "50%"}}
        />
    )

    let nameComponent = name ? name : (
        <Typography fontWeight="bold">{user.name}</Typography>
    )

    let usernameComponent = username ? username : (
        <Typography gutterBottom>@{user.username}</Typography>
    )

    if (linkToProfile) {
        profileImageComponent = <Link href={profileUrl} sx={{transition: "all 0.25s", ":hover": { opacity: 0.75 }, }}>{profileImageComponent}</Link>
        nameComponent = <Link href={profileUrl} underline="hover">{nameComponent}</Link>
        usernameComponent = <Link href={profileUrl} underline="hover" color="textSecondary">{usernameComponent}</Link>
    }

    return (
        <Grid container flexDirection="column" spacing={1}>
            <Grid container item spacing={1}>
                <Grid item>
                    {profileImageComponent} 
                </Grid>
                <Grid item>
                    {nameComponent}
                    {usernameComponent}
                </Grid>
            </Grid> 
            <Grid container item>
                {children}
            </Grid>
        </Grid>
    )
}

export default ProfileContentRow