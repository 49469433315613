import axios, { AxiosResponse } from "axios"
import { createContext, useContext, useState } from "react"
import { SERVER_ADDRESS } from "../constants"
import { MyProfile, patchMyProfile } from "../requests"
import { User } from "../shared/data/types"

type OnAuthSuccess = (user: User) => void

type OnAuthError = (error: any) => void

type AuthContext = {
    user: User | null
    signUp: (
        name: string,
        email: string,
        username: string,
        password: string,
        onSuccess: OnAuthSuccess,
        onError: OnAuthError
    ) => void
    signIn: (
        email: string,
        password: string,
        onSuccess: OnAuthSuccess,
        onError: OnAuthError
    ) => void
    signOut: () => void,
    updateProfile: (
        profile: MyProfile,
        onSuccess: OnAuthSuccess,
        onError: OnAuthError,
        newPhotoFile?: File
    ) => void
}

// Initialize with dummy context
const authContext = createContext<AuthContext>({
    user: null,
    signUp: (name: string, email: string, username: string, password: string, onSuccess: OnAuthSuccess, onError: OnAuthError) => {},
    signIn: (email: string, password: string, onSuccess: OnAuthSuccess, onError: OnAuthError) => {},
    signOut: () => {},
    updateProfile: (profile: MyProfile, onSuccess: OnAuthSuccess, onError: OnAuthError, newPhotoFile?: File) => {}
})

const AuthProvider: React.FC<{}> = ({ children }) => {
    const auth = useProvideAuth()
    return (
        <authContext.Provider value={auth}>
            {children}
        </authContext.Provider>
    )
}

// Hook for child components to get the auth object and
// re-render when it changes
export const useAuth = () => {
    return useContext(authContext)
}

const useProvideAuth = (): AuthContext => {

    const [user, setUser] = useState<User | null>(
        localStorage.user ? JSON.parse(localStorage.user) : null
    )

    const signUp = (
        name: string,
        email: string,
        username: string,
        password: string,
        onSuccess: OnAuthSuccess,
        onError: OnAuthError
    ) => {
        const formData = { name, email, username, password }
        axios.post(`${SERVER_ADDRESS}/users/sign-up/`, formData)
        .then((response) => {
            // @ts-ignore
            const { access, refresh } = response.data
            localStorage.access = access
            localStorage.refresh = refresh
            return axios.get(`${SERVER_ADDRESS}/users/my-profile/`, {
                headers: {
                    "Authorization": "Bearer " + access
                }
            })
        }).then((response) => {
            setUser(response.data)
            localStorage.user = JSON.stringify(response.data)
            onSuccess(response.data)
        }).catch((error) => {
            onError(error)
        })
    }

    const signIn = (
        email: string,
        password: string,
        onSuccess: OnAuthSuccess,
        onError: OnAuthError
    ) => {
        const formData = { email, password }
        axios.post(`${SERVER_ADDRESS}/users/token/`, formData)
        .then(response => {
            // @ts-ignore
            const { access, refresh } = response.data
            localStorage.access = access
            localStorage.refresh = refresh
            return axios.get(`${SERVER_ADDRESS}/users/my-profile/`, {
                headers: {
                    "Authorization": "Bearer " + access
                }
            })
        })
        .then((response) => {
            setUser(response.data)
            localStorage.user = JSON.stringify(response.data)
            onSuccess(response.data)
        }).catch((error) => {
            onError(error)
        })
    }

    const signOut = () => {
        localStorage.removeItem("access")
        localStorage.removeItem("refresh")
        localStorage.removeItem("user")
        setUser(null)
    }

    const updateProfile = (
        profile: MyProfile,
        onSuccess: OnAuthSuccess,
        onError: OnAuthError,
        newPhotoFile?: File
    ) => {
        let patchMyProfilePromise

        if (newPhotoFile) {
            const formData = new FormData()
            formData.append('profile_img', newPhotoFile)
            patchMyProfilePromise = axios.post<any, AxiosResponse<{ link: string }>>(`${SERVER_ADDRESS}/users/my-profile/photos`, formData, {
                headers: {
                "Authorization": "Bearer " + localStorage.access,
                "Content-Type": "content-type': 'multipart/form-data"
                }
            })
            .then((response) => {
                const request: MyProfile = {
                    bio: profile.bio ? profile.bio : user?.bio,
                    name: profile.name ? profile.name : user?.name,
                    profile_photo_url: response.data.link
                }
        
                return patchMyProfile(request)
            })
        } else {
            patchMyProfilePromise = patchMyProfile({
                bio: profile.bio ? profile.bio : user?.bio,
                name: profile.name ? profile.name : user?.name,
                profile_photo_url: user?.profile_photo_url
            })
        }

        patchMyProfilePromise
        .then((response) => {
            setUser(response.data as User)
            localStorage.user = JSON.stringify(response.data as User)
            onSuccess(response.data as User)
        }).catch((error) => {
            onError(error)
        })

    }

    return {
        user,
        signUp,
        signIn,
        signOut,
        updateProfile
    }
}



export default AuthProvider
