import { Modal, Box, Backdrop, Fade } from "@mui/material";

type InputProps = {
    open: boolean;
    onClose: () => void;
    maxWidth: string
}

const BasicModal: React.FC<InputProps> = ({
    open,
    onClose,
    maxWidth,
    children
}) => {
    return (
        <div>
            <Modal
                open={open}
                onClose={onClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{timeout: 0}}
            >
                <Fade in={open}>
                    <Box id="modal-box" sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        bgcolor: "white",
                        width: "100%",
                        maxWidth: maxWidth,
                        borderRadius: 5,
                        maxHeight: "100vh"
                    }}>
                        {children}
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}

export default BasicModal;
