import {
    Grid,
    Typography,
    Button,
    Slider,
    TextField,
    Input,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Alert,
    CircularProgress
} from "@mui/material"
import React, { ChangeEvent, useState } from "react"
import { MAX_LENGTH_PREDICTION_DESCRIPTION } from "../constants"
import BasicModal from "../shared/components/BasicModal"
import { DateTimePicker, LocalizationProvider } from "@mui/lab"
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import FullWidthDivider from "../shared/components/FullWidthDivider"
import InfoTooltip from "../shared/components/InfoTooltip"
import { Prediction } from "../shared/data/types"
import { submitPrediction } from "../requests";

type Props = {
    open: boolean
    onClose: () => void
    onSubmitSuccess: (prediction: Prediction) => void
    originalPrediction: Prediction | null
}

type FormState = "Input" | "AwaitConfirmationWithDeadlineWarning" | "AwaitConfirmation" | "Submitting"

type FormText = {
    title: string
    likelihoodTooltip: string
    descriptionText: string
    evaluationDeadlineTooltip: string
    confirmationDialogTitle: string
    confirmationDialogDescription: string
}

const predictionFormText: FormText = {
    title: "New Prediction",
    likelihoodTooltip: "Enter the likelihood the prediction will come true by the evaluation date. This can be a precentage from 0% to 100% with a precision up to 0.01%.",
    descriptionText: "Express your prediction in 280 characters or less. Make it clear and precise.",
    evaluationDeadlineTooltip: "Set the deadline to evaluate if the prediction comes true. You can submit an evaluation any time before the deadline.",
    confirmationDialogTitle: "Prediction Confirmation",
    confirmationDialogDescription: "Are you sure? Predictions cannot be edited or deleted after creation."
}

const counterPredictionFormText: FormText = {
    title: "New Counter Prediction",
    likelihoodTooltip: "Enter the likelihood the counter prediction will come true by the evaluation date. This can be a precentage from 0% to 100% with a precision up to 0.01%.",
    descriptionText: "Express your prediction in 280 characters or less. Make it clear and precise.",
    evaluationDeadlineTooltip: "Your counter prediction will have the same deadline as the original.",
    confirmationDialogTitle: "Counter Prediction Confirmation",
    confirmationDialogDescription: "Are you sure? Counter predictions cannot be edited or deleted after creation."
}

/**
 * A modal to create predictions or counter-predictions. If originalPrediction is non-null then
 * the modal creates a counter prediction. Otherwise it creates a prediction.
 */
const CreatePredictionModal: React.FC<Props> = ({ open, onClose, onSubmitSuccess, originalPrediction }) => {

    const [formState, setFormState] = useState<FormState>("Input")
    const [submissionError, setSubmissionError] = useState("")

    const [probability, setProbability] = useState<number | string | Array<number | string>>(50)
    const [probabilityText, setProbabilityText] = useState<string>("50")
    const [textFieldIsFocused, setTextFieldIsFocused] = useState(false)
    const [predictionDescription, setPredictionDescription] = useState("")
    const [evaluationDeadline, setEvaluationDeadline] = useState<Date | null>(new Date())

    const formText = originalPrediction ? counterPredictionFormText : predictionFormText

    // For clearing the form after creation success
    const resetFormData = () => {
        setSubmissionError("")
        setProbability(50)
        setTextFieldIsFocused(false)
        setPredictionDescription("")
        setEvaluationDeadline(new Date())
    }

    const handleSliderChange = (event: Event, newValue: number | number[]) => {
        setProbability(newValue)
        setProbabilityText(newValue.toString())
    }

    const handleLikelihoodInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newProbability = Number(event.target.value)
        // Adjust the probability if it's a valid number from 0.00 to 100
        if (!isNaN(newProbability) && newProbability >= 0 && newProbability <= 100) {
            setProbability(newProbability)
        }

        setProbabilityText(event.target.value)
    }

    const onPredictionDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.value.length <= MAX_LENGTH_PREDICTION_DESCRIPTION) {
            setPredictionDescription(event.target.value)
        }
    }

    const onEvaluationDeadlineChange = (event: Date | null) => {
        setEvaluationDeadline(event)
    }

    const handleConfirmation = () => {
        setFormState("Submitting")
        
        if (originalPrediction) {
            // Create a counter prediction
            const counterPredictionData = {
                description: predictionDescription,
                probability: probability,
                evaluate_at: originalPrediction.evaluate_at,
                original_prediction: originalPrediction.id
            }
            submitPrediction(counterPredictionData)
            .then((response) => {
                resetFormData()
                onSubmitSuccess(response.data)
            }).catch((error) => {
                // TODO - better error handling
                setSubmissionError(JSON.stringify(error.response.data))
            }).finally(() => {
                setFormState("Input")
            })
        } else {
            const formattedDate = evaluationDeadline ? evaluationDeadline.toISOString() : ""

            const predictionData = {
                description: predictionDescription,
                probability: probability,
                evaluate_at: formattedDate
            }

            // Create a normal prediction
            submitPrediction(predictionData)
            .then((response) => {
              resetFormData()
              onSubmitSuccess(response.data)
            }).catch((error) => {
                // TODO - better error handling
                setSubmissionError(JSON.stringify(error.response.data))
            }).finally(() => {
                setFormState("Input")
            })
        }
    }

    const handleOnSubmit = () => {
        // If the evaluation deadline is within 24 hours or less ask the user if this was intentional
        // Sometimes they accidentally forget to set the evaluation date and create a prediction that
        // expires immediately
        let oneDayFromNow = new Date()
        oneDayFromNow.setDate(oneDayFromNow.getDate() + 1)
        if (evaluationDeadline && evaluationDeadline < oneDayFromNow) {
            setFormState("AwaitConfirmationWithDeadlineWarning")
        } else {
            setFormState("AwaitConfirmation")
        }
    }

    const predictionDescriptionTextInputLabel = textFieldIsFocused || predictionDescription.length > 0
        ? predictionDescription.length + `/${MAX_LENGTH_PREDICTION_DESCRIPTION}`
        : "Description goes here..."

    return (
        <BasicModal open={open} onClose={onClose} maxWidth="450px">
            <Grid id="submit-prediction-modal-container"
            container
            p={4}
            width="100%"
            maxWidth="450px"
            spacing={1}
            alignContent="flex-start"
            style={{ position: "relative", overflowY: "scroll", maxHeight: "100vh" }}>

                {/* Header */}
                <Grid item xs={12} container>
                    <Typography variant="h4" gutterBottom>
                        {formText.title}
                    </Typography>
                    <Typography variant="h4" gutterBottom style={{ marginRight: "0", marginLeft: "auto", cursor: "pointer" }} onClick={onClose}>
                        X
                    </Typography>
                    <FullWidthDivider />
                </Grid>

                {/* Header for probability input */}
                <Grid item xs={12} container>
                    <Typography pt="3px" alignSelf="center">Likelihood</Typography>
                    <InfoTooltip text={formText.likelihoodTooltip} />
                </Grid>

                {/* Probability input */}
                <Grid item xs={9} >
                    <Slider
                        defaultValue={50}
                        value={typeof probability === 'number' ? probability : 0}
                        min={0}
                        max={100}
                        valueLabelDisplay="auto"
                        valueLabelFormat={(value) => value + "%"}
                        onChange={handleSliderChange}
                        marks={[{ value: 0, label: "0%"}, {value: 100, label: "100%"}]}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Input
                        value={probabilityText}
                        onChange={handleLikelihoodInputChange}
                        size="small"
                        style={{
                            width: "55%",
                            marginLeft: "25px"
                        }}
                        inputProps={{
                            type: "string"
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FullWidthDivider/>
                </Grid>
                
                {/* Description input */}
                <Grid container item xs={12}>
                    <Typography pt="3px" alignSelf="center">Details</Typography>
                    <InfoTooltip text={formText.descriptionText} />
                    <TextField
                        fullWidth
                        value={predictionDescription}
                        label={predictionDescriptionTextInputLabel}
                        variant="outlined"
                        multiline
                        rows={6}
                        onFocus={() => {setTextFieldIsFocused(true)}}
                        onBlur={() => setTextFieldIsFocused(false)}
                        onChange={onPredictionDescriptionChange}
                        style={{marginTop: "5px"}}
                    />
                </Grid>
                <Grid item xs={12} marginTop="15px">
                    <FullWidthDivider/>
                </Grid>

                {/* Evaluation deadline input */}
                <Grid container item xs={12}>
                    <Typography pt="3px" alignSelf="center">Evaluation Deadline</Typography>
                    <InfoTooltip text={formText.evaluationDeadlineTooltip}/>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateTimePicker
                            disabled={originalPrediction !== null}
                            value={originalPrediction !== null ? originalPrediction.evaluate_at : evaluationDeadline}
                            // Only future dates are valid for the evaluation deadline
                            minDate={new Date()}
                            onChange={onEvaluationDeadlineChange}
                            renderInput={(props) => <TextField id="date-time-picker-text-field" {...props}/>}
                        />
                    </LocalizationProvider>
                </Grid>

                {/* Alert if there is a server error */}
                {submissionError && (
                    <Grid item xs={12}>
                        <Alert variant="outlined" severity="error">
                            {submissionError}
                        </Alert>
                    </Grid>
                )}

                {/* Submit button */}
                <Grid item xs={12}>
                    <Button
                        type="submit"
                        variant="contained"
                        onClick={handleOnSubmit}
                        disabled={originalPrediction === null && (predictionDescription.length === 0 || isNaN(Number(probabilityText)))}
                    >
                        {formState === "Submitting" ? (
                            <CircularProgress size={20} color="inherit"/>
                        ) : "Create" }
                    </Button>
                    {/* Confirmation dialogue */}
                    <Dialog open={formState === "AwaitConfirmation" || formState === "AwaitConfirmationWithDeadlineWarning"}>
                        <DialogTitle>{formText.confirmationDialogTitle}</DialogTitle>
                        <DialogContent >
                            {formState === "AwaitConfirmationWithDeadlineWarning" && (
                                <Alert sx={{ mb: 1 }} variant="outlined" severity="warning">
                                    Your evaluation deadline is very close to the current time. Please make sure this is intentional.
                                </Alert>
                            )}
                            <Typography>{formText.confirmationDialogDescription}</Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained" onClick={handleConfirmation}>
                                Yes, I'm sure
                            </Button>
                            <Button variant="text" onClick={() => setFormState("Input")}>
                                Cancel
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Grid>

            </Grid>
        </BasicModal>
    )
}

export default CreatePredictionModal
