import { useState, useReducer, FormEvent } from "react";
import {
    Avatar,
    Button,
    FormControl,
    Typography,
    TextField,
    CircularProgress,
    Box
} from "@mui/material"
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import PasswordTextInput from "./PasswordTextInput";
import { useAuth } from '../account/AuthProvider';

type FormState = "Entry" | "Submitting";

type FormData = {
    name: string,
    email: string,
    username: string,
    password: string
}

type FormErrors = {
    name: string;
    email: string;
    username: string;
    password: string;
}

type FormAction = {
    type: keyof FormData;
    value: string
}

function formReducer(formData: FormData, action: FormAction): FormData {
    const newFormData = {...formData}
    newFormData[action.type] = action.value
    return newFormData;
}

const SignUp: React.FC<{}> = ({}) => {

    const authContext = useAuth()

    const [formData, dispatch] = useReducer(formReducer, {
        name: "",
        email: "",
        username: "",
        password: ""
    })

    const emptyFormErrors = {
        name: "",
        email: "",
        username: "",
        password: ""
    }
    const [formErrors, setFormErrors] = useState<FormErrors>(emptyFormErrors);

    const [formState, setFormState] = useState<FormState>("Entry");

    const handleOnSubmit = (event: FormEvent<HTMLInputElement>) => {
        event.preventDefault()
        setFormState("Submitting")

        authContext?.signUp(
            formData.name,
            formData.email,
            formData.username,
            formData.password,
            (user) => { setFormState("Entry") },
            (error) => { setFormState("Entry") }
        )
    }

    // Shared between all input fields in the form so they have the same look and feel
    const textFieldCommonProps = {
        required: true,
        fullWidth: true,
        variant: "outlined" as const,
        margin: "normal" as const,
        disabled: formState === "Submitting" // During submit we disable data entry
    }

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            margin: 4
            }}
        >
            <Avatar>
                <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
                Sign Up
            </Typography>
            <Box component="form" onSubmit={handleOnSubmit} display="flex" flexDirection="column" width="100%">
                <FormControl fullWidth variant="outlined">
                    <TextField
                        {...textFieldCommonProps}
                        id="name"
                        label="Name"
                        autoFocus
                        onChange={(e) => dispatch({
                            type: "name",
                            value: e.target.value
                        })}
                        value={formData.name}
                        inputProps={{
                            minLength: 3,
                            maxLength: 50
                        }}
                        error={formErrors.name !== ""}
                        helperText={formErrors.name}
                    />
                    <TextField
                        {...textFieldCommonProps}
                        id="username"
                        label="Username"
                        onChange={(e) => dispatch({
                            type: "username",
                            value: e.target.value
                        })}
                        value={formData.username}
                        inputProps={{
                            minLength: 3,
                            maxLength: 50
                        }}
                        error={formErrors.username !== ""}
                        helperText={formErrors.username}
                    />
                    <TextField
                        {...textFieldCommonProps}
                        id="email"
                        label="Email"
                        type="email"
                        onChange={(e) => dispatch({
                            type: "email",
                            value: e.target.value
                        })}
                        value={formData.email}
                        inputProps={{
                            minLength: 3,
                            maxLength: 50
                        }}
                        error={formErrors.email !== ""}
                        helperText={formErrors.email}
                    />
                    <PasswordTextInput
                        textFieldCommonProps={textFieldCommonProps}
                        onPasswordChange={(password) => dispatch({
                            type: "password",
                            value: password
                        })}
                        errorText={formErrors.password}
                        helperText={formErrors.password}
                    />
                    <Button type="submit" fullWidth variant="contained" sx={{mt: 3, mb: 2 }}>
                        {formState === "Submitting" ? <CircularProgress size={20} color="primary"/> : "Sign Up"}
                    </Button>
                </FormControl>
            </Box>
        </Box>
    )


}

export default SignUp;
