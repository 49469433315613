import axios, { AxiosResponse } from 'axios';
import { SERVER_ADDRESS } from "./constants";
import { Evaluation, Follow, Prediction } from "./shared/data/types"

export type PaginatedResponse<T> = {
  count: number
  next: string | null
  previous: string | null
  results: Array<T>
}

const getHeaders = (): Record<string, string> | undefined => {
  if (localStorage.access) {
    return ({
      "Authorization": "Bearer " + localStorage.getItem("access"),
      "Content-Type": "application/json"
    })
  } else {
    return undefined
  }
}

type RefreshPayload = {
  refresh: string
}

type TokenPair = {
  access: string,
  refresh: string
}

const refreshAuth = () => {
  return (axios.post<RefreshPayload, AxiosResponse<TokenPair>>(`${SERVER_ADDRESS}/users/token/refresh/`, {
    refresh: localStorage.getItem("refresh")!
  })
    .catch((error) => {
      localStorage.clear();
      window.location.href = "/";
    })
    .then(response => {
      if (response !== undefined) {
        localStorage.setItem("access", response.data.access)
        localStorage.setItem("refresh", response.data.refresh)
      }
    }));
}

export async function makeAuthedCall<Input=never, Output=never>(httpMethod: string, route: string, body: Input, retryCount=1): Promise<AxiosResponse<Output>> {
  const fullRoute = `${SERVER_ADDRESS}${route}`;
  const headers = getHeaders()
  try {
    if (httpMethod === "post") {
      const request = (axios.post<Input, AxiosResponse<Output>>(fullRoute, body, {
        headers
      }));
      return await request;
    } else if (httpMethod === "get") {
      const request = (axios.get<Input, AxiosResponse<Output>>(fullRoute, {
        headers
      }));
      return await request;
    } else if (httpMethod === "patch") {
      const request = (axios.patch<Input, AxiosResponse<Output>>(fullRoute, body, {
        headers
      }));
      return await request;
    } else if (httpMethod === "put") {
      const request = (axios.put<Input, AxiosResponse<Output>>(fullRoute, body, {
        headers
      }));
      return await request;
    } else if (httpMethod === "delete") {
      const request = (axios.delete<Input, AxiosResponse<Output>>(fullRoute, {
        headers
      }))
      return await request
    } else {
      throw "uh oh";
    }
  } catch (error) {
   if (axios.isAxiosError(error) && error.response) {
      if (error.response.status === 401 && retryCount > 0) {
          await refreshAuth();
          return await makeAuthedCall<Input, Output>(httpMethod, route, body, retryCount=retryCount-1);
      }
    }
    throw error;
  }
}

export type MyProfile = {
  bio?: string,
  name?: string,
  profile_photo_url?: string
  username?: string
}

export const patchMyProfile = (request: MyProfile) => {
  return makeAuthedCall<MyProfile, MyProfile>("patch", "/users/my-profile/", request);
}
export const getMyProfile = () => {
  return makeAuthedCall<null, MyProfile>("get", "/users/my-profile/", null);
}

type ChangePassword = {
  old_password: string,
  new_password: string
}

export const changePassword = (request: ChangePassword) => {
  return makeAuthedCall<ChangePassword, null>("put", "/users/change-password", request);
}

export type SubmitEvaluationRequest = {
  outcome: boolean
  justification: string
}

export const submitEvaluation = (request: SubmitEvaluationRequest, predictionId: number) => {
  return makeAuthedCall<SubmitEvaluationRequest, Evaluation>("put", `/my-predictions/${predictionId}/evaluation`, request);
}

export type SubmitPredictionRequest = {
  description: string,
  probability: any,
  evaluate_at: string
}

export const submitPrediction = (request: SubmitPredictionRequest) => {
  return makeAuthedCall<SubmitPredictionRequest, Prediction>("post", "/my-predictions", request);
}

export const getPrediction = (id: string) => {
  return makeAuthedCall<null, Prediction>("get", `/predictions/${id}`, null)
}

export type Ordering = "RECENTLY_MADE" | "RECENTLY_EVALUATED" | "EVALUATED_SOON" | "COUNTER_PREDICTIONS"

export const fetchPredictions = (username: string, ordering: Ordering | undefined, pageSize: number, offset: number) => {
  let url;
  if (ordering !== undefined) {
    url = `/predictions/${username}?group=${ordering}&limit=${pageSize}&offset=${offset}`
  } else {
    url = `/predictions/${username}?limit=${pageSize}&offset=${offset}`
  }

  return makeAuthedCall<null, PaginatedResponse<Prediction>>("get", url, null)
}

export const fetchCounterPredictions = (predictionId: number) => {
  const url = `/predictions/${predictionId}/counter-predictions`
  return makeAuthedCall<null, PaginatedResponse<Prediction>>("get", url, null)
}

type PredictionViewRequest = {
  prediction: number
}

export const viewPrediction = (predictionId: number) => {
  return makeAuthedCall<PredictionViewRequest, null>("post", "/prediction-views", {prediction: predictionId})
}

/**
 * Fetch the followers of the supplied username
 */
export const fetchFollowers = (username: string, pageSize: number, offset: number) => {
  const url = `/users/profiles/${username}/followers?limit=${pageSize}&offset=${offset}`
  return makeAuthedCall<null, PaginatedResponse<Follow>>("get", url, null)
}

/**
 * Fetch the users that the supplied username follows
 */
export const fetchFollows = (username: string, pageSize: number, offset: number) => {
  const url = `/users/profiles/${username}/follows?limit=${pageSize}&offset=${offset}`
  return makeAuthedCall<null, PaginatedResponse<Follow>>("get", url, null)
}

/**
 * Fetch a follower/followed relationship
 * 
 * @param followingUsername - username of the user who is following
 * @param followedUsername - username of the user being followed
 * @returns the follow if it exists. 404's if it doesn't exist
 */
export const fetchFollowRelationship = (followingUsername: string, followedUsername: string): Promise<AxiosResponse<Follow>> => {
  const url = `/users/profiles/${followingUsername}/follows/${followedUsername}`
  return makeAuthedCall<null, Follow>("get", url, null)
}

/**
 * Make the currently authenticated user follow the user with the supplied username
 */
export const follow = (username: string): Promise<AxiosResponse<Follow>> => {
  const url = `/users/profiles/my-follows`
  const body = { username: username }
  return makeAuthedCall<{ username: string }, Follow>("post", url, body)
}


/**
 * Make "followerUsername" unfollow "userToUnfollowUsername"
 */
export const unfollow = (followerUsername: string, userToUnfollowUsername: string): Promise<AxiosResponse<null>> => {
  const url = `/users/profiles/${followerUsername}/follows/${userToUnfollowUsername}`
  return makeAuthedCall<null, null>("delete", url, null)
}
