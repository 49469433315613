import {
    Button,
    Grid,
    Typography,
    Box,
    Paper,
    Divider,
    Link,
    useMediaQuery,
    useTheme
} from "@mui/material"
import SignUp from "./login/SignUp"
import { useState, useRef, useEffect, useCallback } from "react"
import { Redirect } from "react-router"
import SignIn from "./login/SignIn"
import BasicModal from "./shared/components/BasicModal"
import HomeBackgroundImage from "./assets/images/home-background.jpg"
import NewPredictionScreenshot from "./assets/images/new-prediction-screenshot.jpg"
import NewEvaluationScreenshot from "./assets/images/new-evaluation-screenshot.jpg"
import PredictionPageScreenshot from "./assets/images/prediction-page-screenshot.jpg"
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown'
import LooksOneIcon from '@mui/icons-material/LooksOne'
import LooksTwoIcon from '@mui/icons-material/LooksTwo'
import Looks3Icon from '@mui/icons-material/Looks3'
import { useAuth } from "./account/AuthProvider"
import RootMeta from "./shared/components/RootMeta"

const Home: React.FC<{}> = ({}) => {

    const authContext = useAuth()
    const user = authContext?.user

    const [showSignInModal, setShowSignInModal] = useState(false)
    const [showSignUpModal, setShowSignUpModal] = useState(false)

    const theme = useTheme()
    const isWiderThanSmBreakpoint = useMediaQuery(theme.breakpoints.up('sm')) // returns true when screen width is larger than the sm breakpoint

    // Scroll to this when the "Learn more" icon is pressed
    const learnMoreSectionRef = useRef<HTMLDivElement>(null)

    // Transition directly from SignIn modal to SignUp modal
    function onSignUpInstead() {
        setShowSignUpModal(true)
        setShowSignInModal(false)
    }

    const [yPos, setYPos] = useState(0)
    const handleScroll = useCallback((event) => {
        setYPos(window.scrollY)
    }, [])

    useEffect(() => {
        window.addEventListener("scroll", handleScroll)
        return () => {
            window.removeEventListener("scroll", handleScroll)
        }
    }, [handleScroll])

    if (user) {
        return <Redirect to={"/user/" + user.username}/>
    } else {
        return (
            <Grid container flexDirection="row">
                <RootMeta/>
                <img hidden={!isWiderThanSmBreakpoint} src={HomeBackgroundImage} style={{
                    zIndex: -1,
                    maxHeight: "90vh",
                    position: "fixed",
                    left: "60%",
                    opacity: Math.max(0.05, 1.0 - yPos / (1.5 * window.innerHeight))
                }}/>
                
                {/* Value prop text on the left */}
                <Grid item xs={12} md={7} container flexDirection="column" justifyContent="center" pl="5%" pt="5%">
                    <Box display="flex" flexDirection="column">
                            <Typography variant="h1" gutterBottom>
                                Foresight
                            </Typography>
                            <Typography variant="h2" gutterBottom>
                                An alternative to the cult of <Box fontStyle="italic">often wrong but never in doubt</Box>
                            </Typography>
                            <Typography variant="h3" gutterBottom>
                                Make and evaluate predictions for anyone to see
                            </Typography>
                            <Button onClick={() => setShowSignUpModal(true)} variant="contained" style={{maxWidth: "140px"}}>
                                Get Started
                            </Button>  
                            <BasicModal open={showSignUpModal} onClose={() => setShowSignUpModal(false)} maxWidth="450px">
                                <SignUp />
                            </BasicModal>
                            <Button onClick={() => setShowSignInModal(true)} style={{maxWidth: "140px"}}>
                                Sign in
                            </Button> 
                            <BasicModal open={showSignInModal} onClose={() => setShowSignInModal(false)} maxWidth="450px">
                                <SignIn onSignupInstead={onSignUpInstead}/>
                            </BasicModal>
                    </Box>
                </Grid>

                {/* Vertical whitespace */}
                <Grid item xs={12} style={{height: "60px"}}/>

                {/* "Learn more" button */}
                <Grid item xs={12}>
                    <Box alignItems="center" display="flex" flexDirection="column">
                        <Typography fontWeight="light">
                            Learn more
                        </Typography>
                        <Button color="inherit" onClick={() => {
                            learnMoreSectionRef.current?.scrollIntoView({
                                block: "start",
                                inline: "nearest",
                                behavior: "smooth"
                            })
                        }}>
                            <ExpandCircleDownIcon/>
                        </Button>
                    </Box>
                </Grid>

                {/* Vertical whitespace */}
                <Grid item xs={12} style={{height: "100px"}}/>

                {/* First "Learn more" section */}
                <Grid container alignItems="center" flexDirection="column" item sm={6} xs={12} ref={learnMoreSectionRef}>
                    <Grid container flexDirection="column" item maxWidth="70%">
                        <Grid item>
                            <LooksOneIcon sx={{fontSize: 50}}/>
                        </Grid>
                        <Grid item>
                            <Typography variant="h4" gutterBottom>
                                Make a prediction and set the deadline
                            </Typography>
                        </Grid>
                        <Grid item >
                            <Typography paragraph>
                                Create a prediction on any topic. Predictions
                                should be clear and unambiguous in their outcome.
                                <br/><br/>
                                Set a deadline for when you have to evaluate
                                the outcome of the prediction.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item sm={6} xs={12} justifyContent="center" alignItems="center">
                    <Grid item style={{maxWidth: "70%"}}>
                        <Paper elevation={16}>
                            <img src={NewPredictionScreenshot} style={{maxWidth: "100%", borderRadius: 5, marginBottom: "-5px"}}/>
                        </Paper>
                    </Grid>
                </Grid>

                {/* Vertical whitespace */}
                <Grid item xs={12} style={{height: "100px"}}/>

                {/* Second "Learn more" section */}
                {isWiderThanSmBreakpoint ? (
                <>
                    <Grid id="learn-more-2" container item sm={6} xs={12} justifyContent="center" alignItems="center">
                        <Grid item style={{"maxWidth": "70%"}}>
                            <Paper elevation={16}>
                                <img src={NewEvaluationScreenshot} style={{maxWidth: "100%", borderRadius: 5, marginBottom: "-5px"}}/>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Grid container alignItems="center" flexDirection="column" item sm={6} xs={12}>
                        <Grid container item maxWidth="70%" flexDirection="column">
                        <Grid item>
                            <LooksTwoIcon sx={{fontSize: 50}}/>
                        </Grid>
                        <Grid item>
                            <Typography variant="h4" gutterBottom>
                                Evaluate predictions before their deadline
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography paragraph>
                                You can evaluate a prediction any time before the deadline.
                                <br/><br/>
                                To evaluate, select the appropriate outcome and justify it with an explanation.
                            </Typography>
                        </Grid>
                        </Grid>
                    </Grid>
                </>
                ) : (
                    <>
                    <Grid container alignItems="center" flexDirection="column" item sm={6} xs={12}>
                        <Grid container item maxWidth="70%" flexDirection="column">
                        <Grid item>
                            <LooksTwoIcon sx={{fontSize: 50}}/>
                        </Grid>
                        <Grid item>
                            <Typography variant="h4" gutterBottom>
                                Evaluate predictions before their deadline
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography paragraph>
                                You can evaluate a prediction any time before the deadline.
                                <br/><br/>
                                To evaluate, select the appropriate outcome and justify it with an explanation.
                            </Typography>
                        </Grid>
                        </Grid>
                    </Grid>
                    <Grid id="learn-more-2" container item sm={6} xs={12} justifyContent="center" alignItems="center">
                        <Grid item style={{"maxWidth": "70%"}}>
                            <Paper elevation={16}>
                                <img src={NewEvaluationScreenshot} style={{maxWidth: "100%", borderRadius: 5, marginBottom: "-5px"}}/>
                            </Paper>
                        </Grid>
                    </Grid>
                    </>
                )}

                {/* Vertical whitespace */}
                <Grid item xs={12} style={{height: "100px"}}/>

                {/* Third "Learn more" */}
                <Grid container alignItems="center" flexDirection="column" item sm={6} xs={12} >
                    <Grid item container maxWidth="70%" flexDirection="column">
                    <Grid item>
                        <Looks3Icon sx={{fontSize: 50}}/>
                    </Grid>
                    <Grid item>
                        <Typography variant="h4" gutterBottom>
                            Share your profile and showcase predictions
                        </Typography>
                    </Grid>
                    <Grid item >
                        <Typography paragraph>
                            Share your predictions and participate in a culture
                            of honesty and accountability. Your profile is an
                            uneditable record of your predictions.
                            <br/><br/>
                            Do you have predictions that didn't come true? That's ok!
                            Presenting them here means that others can trust that you
                            admit when you're wrong.
                            <br/><br/>
                            Do you have predictions that came true?
                            That's great! Others will see your track record and
                            be willing to listen to what you have to say.
                        </Typography>
                    </Grid>
                    </Grid>
                </Grid>
                <Grid container item sm={6} xs={12} justifyContent="center" alignItems="center">
                    <Grid item style={{"maxWidth": "85%"}}>
                        <Paper elevation={16}>
                            <img src={PredictionPageScreenshot} style={{maxWidth: "100%", borderRadius: 5}}/>
                        </Paper>
                    </Grid>
                </Grid>

                {/* Vertical whitespace */}
                <Grid item xs={12} style={{height: "100px"}}/> 

                <Grid item xs={12} mb="10px">
                    <Divider />
                </Grid>
            
                <Grid item container flexDirection="column">
                    <Grid item>
                        <Link href="/faq">
                            <Typography>
                                FAQ
                            </Typography>
                        </Link>
                    </Grid>
                    <Grid item mt="10px">
                        <Typography>
                            Feedback?
                            <br/>
                            <Link href="mailto:4sit3business@gmail.com">4sit3business@gmail.com</Link>
                        </Typography>
                    </Grid>

                    <Grid item mt="10px">
                        <Typography color="textSecondary">
                            © Foresight - All rights reserved.
                        </Typography>
                    </Grid>
                </Grid>

            </Grid>
        )
    }
}

export default Home
