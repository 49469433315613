import { Grid, Typography, Box, Accordion, AccordionSummary, AccordionDetails } from "@mui/material"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TopBar from "./TopBar";
import { MAX_PAGE_WIDTH } from "./constants";

const FaqPage: React.FC<{}> = ({}) => {
    return (
        <div>
        <TopBar />
        <Box id="faq-page-wrapper" display="flex" flexDirection="column" alignItems="center">
            <Grid container flexDirection="row" justifyContent="center" height="100%" maxWidth={MAX_PAGE_WIDTH}>
                <Grid id="faq-grid" container item height="100%" xs={12} >
                    <Grid item xs={12}>
                        <Typography variant="h1" textAlign="center">
                            About Foresight
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Accordion defaultExpanded>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography fontWeight="bold">
                                    What is Foresight?
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    We often call on experts to help us make predictions. But we
                                    don't have a good way of evaluating an expert's past predictions.
                                    That's why we've created Foresight, a platform where people can publicly display
                                    their predictions all in one place, so that anyone can review their prediction
                                    history and evaluate their credibility.
                                    <br/><br/>
                                    <em>Often wrong, never in doubt</em> is the phrase we chose to describe what we're
                                    against. Too often, impactful real world decisions are informed by bold claims that turn out to be
                                    wildly incorrect. With predictions on Foresight we can evaluate an expert's track record before trusting them.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography fontWeight="bold">
                                    Who can make predictions?
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    Anyone! We want anyone interested in making predictions
                                    to come to Foresight and do it here. But we highlight experts as prediction
                                    makers because having their predictions on the platform would be especially
                                    useful to the public.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography fontWeight="bold">
                                    What is an evaluation?
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    An evaluation states the outcome of a prediction. It answers the question
                                    <em> did this prediction come true? </em> You can evaluate a prediction any time
                                    up to the evaluation deadline. A prediction is evaluated by the same user
                                    who made the prediction.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography fontWeight="bold">
                                    What's the point of evaluating my own predictions?
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    Even if a user evaluates their own predictions, we believe they are incentivized
                                    to evaluate them honestly. Since predictions and evaluations are public, if
                                    a prediction didn't come true but a user sets the evaluation to "came true" then
                                    anyone can read the prediction and easily see they're being dishonest.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography fontWeight="bold">
                                    Why can't I create new predictions?
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    If you can't create new predictions then you have one or more predictions
                                    that have not been evaluted yet and are past the evaluation deadline. You must
                                    evaluate all of these predictions before creating new ones.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
        </div>
    )
}

export default FaqPage
