import { Prediction } from "../shared/data/types"

/**
 * Return the message to be displayed on the profile page when the user has expired predictions that are missing an evaluation
 * 
 * @param profileUsername username of the profile to dispay the alert on
 * @param userOwnsProfile true if the user viewing the profile is the user that OWNS the profile, false otherwise
 * @returns 
 */
export const getExpiredPredictionsAlertMessage = (profileUsername: string, userOwnsProfile: boolean) => {
    return (userOwnsProfile ? "You have" : `${profileUsername} has`) + " to evaluate " + (userOwnsProfile ? "your" : "their") + " expired predictions before creating new ones"
}

/**
 * Retrieve a prediction's status emoji
 */
export const getPredictionStatusEmoji = (prediction: Prediction): "⏳" | "✅" | "❌" => {
    if (prediction.evaluation !== null && prediction.evaluation.outcome) {
        return "✅"
    }

    if (prediction.evaluation !== null && !prediction.evaluation.outcome) {
        return "❌"
    }

    return "⏳"
}

/**
 * Get the user facing probability for prediction. Whole number probabilities are returned
 * from the backend with decimal digits, e.g. '75.00'. We'll round to the whole number when
 * it's an integer. Otherwise we'll show the decimal points.
 */
export const getPredictionLikelihoodText = (prediction: Prediction) => {
    return Number.isInteger(Number(prediction.probability)) ? Math.round(prediction.probability) : prediction.probability
}
