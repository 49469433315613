import { Grid, Button, Alert, Typography } from "@mui/material"
import { useState } from "react";
import { useAuth } from "../account/AuthProvider"
import { Evaluation, Prediction } from "../shared/data/types";
import InfoTooltip from "../shared/components/InfoTooltip";
import { formatDate } from "../shared/data/dateUtils";
import SubmitEvaluationModal from "../modals/SubmitEvaluationModal";

type Props = {
    prediction: Prediction
    onSubmitEvaluationSuccess: (evaluation: Evaluation) => void
}

const EvaluationRow: React.FC<Props> = ({ prediction, onSubmitEvaluationSuccess }) => {

    const { user } = useAuth()

    const [showSubmitModal, setShowSubmitModal] = useState(false)

    const onSubmitSuccess = (evaluation: Evaluation) => {
        setShowSubmitModal(false)
        onSubmitEvaluationSuccess(evaluation)
    }

    const loggedInUserOwnsPrediction = user && user.username === prediction.user.username
    const alreadyEvaluated = prediction.evaluation !== null
    const pastDeadline = (new Date(prediction.evaluate_at)) < (new Date())

    // Render to submit evaluation
    if (loggedInUserOwnsPrediction && !alreadyEvaluated && !pastDeadline) {
        console.log("Render submit evaluation button")
        return (
            <Grid container item style={{paddingLeft: 0}} flexDirection="column" alignItems="flex-start">
                <Typography variant="h5" gutterBottom>
                    Evaluation
                </Typography>
                <Button variant="contained" onClick={() => setShowSubmitModal(true)}>
                    Evaluate
                </Button>
                <SubmitEvaluationModal
                    open={showSubmitModal}
                    prediction={prediction}
                    onSubmitEvaluation={onSubmitSuccess}
                    onClose={() => setShowSubmitModal(false)}
                />
            </Grid>
        )
    }

    // Render to edit an existing evaluation
    if (loggedInUserOwnsPrediction && alreadyEvaluated && !pastDeadline) {
        console.log("Render evaluation with edit button")
        return (
            <Grid container item style={{paddingLeft: 0}} spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Evaluation
                    </Typography>
                    <Typography color="textSecondary" gutterBottom>
                        {formatDate(prediction.evaluation!.created_at)}
                    </Typography>
                    <Typography gutterBottom>
                        {prediction.evaluation?.justification}
                    </Typography>
                </Grid>
                <Grid item>
                    <Button variant="contained" onClick={() => setShowSubmitModal(true)}>
                        Edit
                    </Button>
                    <InfoTooltip text="You can edit an evaluation any time before the deadline."/>
                </Grid>
                <SubmitEvaluationModal
                    open={showSubmitModal}
                    prediction={prediction}
                    onSubmitEvaluation={onSubmitSuccess}
                    onClose={() => setShowSubmitModal(false)}
                />
            </Grid>
        )
    }

    if (loggedInUserOwnsPrediction && !alreadyEvaluated && pastDeadline) {
        console.log("Render past deadline message with evalute button")
        return (
            <Grid container item style={{paddingLeft: 0}} spacing={1}>
                <Grid item>
                    <Alert severity="error">
                        The deadline has passed. You must evaluate this prediction before you can create new predictions!
                    </Alert>
                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained" onClick={() => setShowSubmitModal(true)}>
                        Evaluate
                    </Button>
                </Grid>
                <SubmitEvaluationModal
                    open={showSubmitModal}
                    prediction={prediction}
                    onSubmitEvaluation={onSubmitSuccess}
                    onClose={() => setShowSubmitModal(false)}
                />
            </Grid>
        )
    }

    // Render text to say that this prediction hasn't been evaluated yet
    if (!loggedInUserOwnsPrediction && !alreadyEvaluated && !pastDeadline) {
        console.log("Render 'Awaiting prediction' message")
        return (
            <Grid container item style={{paddingLeft: 0}} flexDirection="column" alignItems="flex-start">
                <Typography variant="h5">Evaluation</Typography>
                <Typography>
                    {prediction.user.name} hasn't submitted an evaluation yet.
                </Typography>
            </Grid>
        )
    }

    // Render text to say that this prediction hasn't been evaluated yet AND it's past due
    if (!loggedInUserOwnsPrediction && !alreadyEvaluated && pastDeadline) {
        console.log("Render past deadline message without evaluation button")
        return (
            <Grid container item style={{paddingLeft: 0}}>
                <Alert severity="error">
                    The deadline has passed. {prediction.user.name} must evaluate this prediction before they can create new predictions!
                </Alert>
            </Grid>
        )
    }

    // Render evaluation for non-owner user. We just show the evalution and nothing else
    if (!loggedInUserOwnsPrediction && alreadyEvaluated) {
        return (
            <Grid container item style={{paddingLeft: 0}}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Evaluation
                    </Typography>
                    <Typography color="textSecondary">
                        {formatDate(prediction.evaluation!.created_at)}
                    </Typography>
                </Grid>
                {prediction.evaluation!.created_at > prediction.evaluate_at && (
                    <Grid item style={{marginTop: "10px", marginBottom: "10px"}}>
                        <Alert severity="warning">This prediction was evaluated past its deadline</Alert>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Typography paragraph>
                        {prediction.evaluation?.justification}
                    </Typography>
                </Grid>
            </Grid>
        )
    }

    // Just render the evaluation
    if (alreadyEvaluated && pastDeadline) {
        console.log("Render evaluation without button")
        return (
            <Grid container item style={{paddingLeft: 0}}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Evaluation
                    </Typography>
                    <Typography color="textSecondary">
                        {formatDate(prediction.evaluation!.created_at)}
                    </Typography>
                </Grid>
                {prediction.evaluation!.created_at > prediction.created_at && (
                    <Grid item style={{marginTop: "10px", marginBottom: "10px"}}>
                        <Alert severity="warning">This prediction was evaluated past its deadline</Alert>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Typography paragraph>
                        {prediction.evaluation?.justification}
                    </Typography>
                </Grid>
            </Grid>
        )
    }

    // We should never get here
    console.error("Error: reached supposedly unreachable code in EvaluationRow")
    return <div></div>
}

export default EvaluationRow;
