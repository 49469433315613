import React, { useEffect, useState } from "react"
import { Prediction } from "../shared/data/types"
import { Grid, Typography, Link, Box, ListItemButton } from "@mui/material"
import { formatDate } from "../shared/data/dateUtils"
import { getPredictionLikelihoodText, getPredictionStatusEmoji } from "./profileUtils"
import { getPrediction } from "../requests"
import { DEFAUlT_IMAGE } from "../constants"
import { useHistory } from "react-router"

type InputProps = {
    showTitle: boolean; // When set to true, the large "Prediction" title will be visible
    counterPredictionPreviewClickable: boolean
    prediction: Prediction;
}

/**
 * Prediction details as seen on the profile page 
 */
const PredictionRow: React.FC<InputProps> = ({ showTitle, counterPredictionPreviewClickable, prediction }) => {

    const history = useHistory()

    const [originalPrediction, setOriginalPrediction] = useState<Prediction | null>(null)

    useEffect(() => {
        if (prediction.original_prediction) {
            getPrediction(prediction.original_prediction.toString())
            .then((response) => {
                setOriginalPrediction(response.data) 
            })
        } else {
            setOriginalPrediction(null)
        }
    }, [prediction])

    // Wrapping the original prediction preview in a ListItemButton
    // gives it a nice click effect. This is slightly hacky because
    // normally a ListItemButton goes inside a ListItem component,
    // but oh well.
    const OriginalPredictionWrapper: React.FC<{}> = ({children}) => {
        if (counterPredictionPreviewClickable && originalPrediction) {
            return (
                <ListItemButton onClick={() => history.push(`/predictions/${originalPrediction.id}`)} sx={{borderRadius: 2, padding: 0}}>
                    {children}
                </ListItemButton>
            )
        } else {
            return <>{children}</>
        }
    }

    return (
        <Grid container flexDirection="column">
            {showTitle && <Typography variant="h5">{prediction.original_prediction ? "Counter Prediction" : "Prediction"}</Typography>}
            <Typography variant="h4"> 
                {getPredictionStatusEmoji(prediction)} {getPredictionLikelihoodText(prediction)}%
            </Typography>
            <Typography color="textSecondary">
                {formatDate(prediction.created_at)}
            </Typography>
            {getDeadlineLabel(prediction)}
            <Typography style={{height: "100%"}} gutterBottom>
                {prediction.description}
            </Typography>
            {originalPrediction && (
                <OriginalPredictionWrapper>
                    <Box sx={{borderRadius: 2, border: 1, borderColor: "lightGray"}} width="100%">
                        <Grid container flexDirection="column" padding={1}>
                            <Grid container item flexDirection="row" mb={0.5}>
                                <img
                                    src={originalPrediction.user.profile_photo_url ? originalPrediction.user.profile_photo_url : DEFAUlT_IMAGE}
                                    style={{ maxWidth: "30px", maxHeight: "30px", borderRadius: "50%"}}
                                />
                                <Typography fontWeight="bold" ml={1}>
                                    {originalPrediction.user.name}
                                </Typography>
                                <Typography ml={0.25}>
                                    @{originalPrediction.user.username}
                                </Typography>
                                <Typography ml={0.5} color="textSecondary">
                                    {formatDate(originalPrediction.created_at)}
                                </Typography>
                            </Grid>
                            <Typography>
                                {getPredictionStatusEmoji(originalPrediction)} {getPredictionLikelihoodText(originalPrediction)}%
                            </Typography>
                            <Typography>
                                {originalPrediction.description} 
                            </Typography>
                        </Grid>
                    </Box>
                </OriginalPredictionWrapper>
            )}
        </Grid>
    )
}

const getDeadlineLabel = (prediction: Prediction) => {
    const deadlineString = formatDate(prediction.evaluate_at) + " deadline"

    if (!prediction.evaluation && (new Date(prediction.evaluate_at)) < (new Date())) {
        return (
            <Typography style={{color: "red"}} gutterBottom>
                {deadlineString}
            </Typography>
        )
    } else {
        return (
            <Typography color="textSecondary" gutterBottom>
                {deadlineString}
            </Typography>
        )
    }
}

export default PredictionRow
