const DEFAUlT_IMAGE = "https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png";
const SERVER_ADDRESS = "https://api.4sit3.com"
const MAX_LENGTH_PREDICTION_DESCRIPTION = 280
const JUSTIFICATION_CHARACTER_LIMIT = 5000
const MAX_PAGE_WIDTH = "70%"

export {
    DEFAUlT_IMAGE,
    SERVER_ADDRESS,
    MAX_LENGTH_PREDICTION_DESCRIPTION,
    JUSTIFICATION_CHARACTER_LIMIT,
    MAX_PAGE_WIDTH
}
