import {
    FormControlLabel,
    Radio,
    RadioGroup,
    TextField,
    Button,
    Typography,
    Grid,
    Alert,
    CircularProgress
} from "@mui/material";
import { ChangeEvent, useState } from "react";
import { JUSTIFICATION_CHARACTER_LIMIT } from "../constants";
import BasicModal from "../shared/components/BasicModal";
import FullWidthDivider from "../shared/components/FullWidthDivider";
import InfoTooltip from "../shared/components/InfoTooltip";
import { Evaluation, Prediction } from "../shared/data/types";
import { submitEvaluation, SubmitEvaluationRequest } from "../requests";


type Props = {
    open: boolean
    prediction: Prediction
    onSubmitEvaluation: (evaluation: Evaluation) => void
    onClose: () => void
}

type FormState = "Input" | "Submitting"

const SubmitEvaluationModal: React.FC<Props> = ({
    open,
    prediction,
    onSubmitEvaluation,
    onClose,
}) => {

    const [formState, setFormState] = useState<FormState>("Input")
    // If the evaluation exists then we are in edit mode and can prepopulate the form data
    const [cameTrue, setCameTrue] = useState<"true" | "false" | "">(prediction.evaluation ? (prediction.evaluation.outcome ? "true" : "false") : "");
    const [justification, setJustification] = useState(prediction.evaluation ? prediction.evaluation.justification : "");
    const [justificationFieldFocused, setJusitifcationFieldFocused] = useState(false)
    const [submitEvaluationError, setSubmitEvaluationError] = useState("")

    const resetFormData = () => {
        setFormState("Input")
        setCameTrue("")
        setJustification("")
        setJusitifcationFieldFocused(false)
        setSubmitEvaluationError("")
    }

    const onJustificationChange = (e: ChangeEvent<HTMLInputElement>) => {
        setJustification(e.target.value)
    }

    const onCameTrueChanged = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === "true" || e.target.value === "false") {
            setCameTrue(e.target.value);
        } else {
            setCameTrue("")
        }
    }

    const handleOnSubmit = () => {
        setFormState("Submitting")
        const requestBody: SubmitEvaluationRequest = {
            outcome: cameTrue === "true" ? true : false,
            justification: justification
        }
        submitEvaluation(requestBody, prediction.id)
          .then((response) => {
            console.log("Received evaluation submission data!")
            console.log(response.data)
            resetFormData();
            onSubmitEvaluation(response.data)
        }).catch((error) => {
            console.log("submit evaluation failure with error:")
            console.log(error)
            // TODO - better error handling
            setSubmitEvaluationError("Whoops! Something went wrong...")
        }).finally(() => {
            setFormState("Input")
        })
    }

    let submitButtonLabel
    if (formState === "Submitting") {
        submitButtonLabel = <CircularProgress size={20}/>
    } else if (prediction.evaluation) {
        submitButtonLabel = "Update"
    } else {
        submitButtonLabel = "Create"
    }
    
    const justificationTextFieldLabel = justificationFieldFocused || justification.length > 0 ? justification.length + `/${JUSTIFICATION_CHARACTER_LIMIT}` :
        "Explanation goes here..."

    return (
        <BasicModal open={open} onClose={onClose} maxWidth="500px">
            <Grid
                id="submit-evaluation-modal-container"
                width="100%"
                maxWidth="500px"
                spacing={1}
                p={4}
                alignContent="flexStart"
                container
                style={{ position: "relative", overflowY: "scroll", maxHeight: "100vh" }}>

                <Grid item xs={12} container>
                    <Typography variant="h4" gutterBottom>
                        {prediction.evaluation ? "Edit Evaluation" : "New Evaluation"}
                    </Typography>
                    <Typography variant="h4" gutterBottom style={{ marginRight: "0", marginLeft: "auto", cursor: "pointer" }} onClick={onClose}>
                        X
                    </Typography>
                    <FullWidthDivider />
                </Grid>

                <Grid container item xs={12}>
                    {/* The typography looks slightly off center from the infotooltip, even when alignSelf="center". Hence the 3px top padding */}
                    <Typography pt="3px" alignSelf="center">
                        Outcome
                    </Typography>
                    <InfoTooltip text="Select whether your prediction came true or didn't come true. Remember, people will judge your evaluation against the facts so it's in your best interest to answer honestly!"/>
                </Grid>

                <Grid item xs={12}>
                    <RadioGroup
                        style={{marginBottom: "10px"}}
                        onChange={onCameTrueChanged}
                        value={cameTrue}
                    >
                        <FormControlLabel value="true" control={<Radio />} label="Came true" />
                        <FormControlLabel value="false" control={<Radio />} label="Didn't come true" />
                    </RadioGroup>
                    <FullWidthDivider />
                </Grid>

                <Grid container item xs={12} >
                    {/* The typography looks slightly off center from the infotooltip, even when alignSelf="center". Hence the 3px top padding */}
                    <Typography pt="3px" alignSelf="center">
                        Explanation
                    </Typography>
                    <InfoTooltip text="Here's your chance to explain the outcome! This could be as simple as linking to a website/article or a full essay. You have up to 5000 characters."/>
                    <TextField
                        fullWidth
                        multiline
                        label={justificationTextFieldLabel}
                        rows={10}
                        onChange={onJustificationChange}
                        onFocus={() => setJusitifcationFieldFocused(true)}
                        onBlur={() => setJusitifcationFieldFocused(false)}
                        value={justification}
                        style={{
                            marginTop: "5px"
                        }}
                    />
                </Grid>
                
                {/* Alert if there is a server error */}
                {submitEvaluationError && (
                    <Grid item xs={12} marginTop="10px">
                        <Alert variant="outlined" severity="error">
                            {submitEvaluationError}
                        </Alert>
                    </Grid>
                )}

                <Grid item xs={12} marginTop="10px">
                    <Button
                        type="submit"
                        variant="contained"
                        disabled={cameTrue === "" || justification === ""}
                        onClick={handleOnSubmit}
                    >
                        {submitButtonLabel}
                    </Button>
                </Grid>
            </Grid>
        </BasicModal>
    )
}

export default SubmitEvaluationModal
