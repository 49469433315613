import { AppBar, Box, Toolbar, IconButton, Link } from "@mui/material"
import Home from "./assets/icons/home-5-64.png"
import Gear from "./assets/icons/gear-white.png"
import Logout from "./assets/icons/logout-64.png"
import Avatar from "./assets/icons/white-avatar.png"
import { useAuth } from './account/AuthProvider';

type Props = {}

const TopBar: React.FC<Props> = () => {

    const { user, signOut } = useAuth()

    const handleLogout = () => {
        signOut()
        window.location.href = "/"
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Toolbar >
                    {user !== null ? (
                        <>
                        <Link href={`/user/${user?.username}`}>
                            <IconButton size="large" edge="start" color="inherit" >
                                <img src={Avatar} style={{maxWidth: "25px", maxHeight: "25px", backgroundColor: "transparent"}}/>
                            </IconButton>
                        </Link>
                    
                        <Box sx={{flexGrow: 1}}/>
                    
                        <Link href="/account">
                            <IconButton size="large" edge="start" color="inherit">
                                <img src={Gear} style={{maxWidth: "25px", maxHeight: "25px", backgroundColor: "transparent"}}/>
                            </IconButton>
                        </Link>
                    
                    
                        <IconButton size="large" edge="end" color="inherit" onClick={handleLogout}>
                            <img src={Logout} style={{maxWidth: "25px", maxHeight: "25px", backgroundColor: "transparent"}}/>
                        </IconButton>
                        </>
                    ) : (
                        <>
                        <Link href="/account">
                             <IconButton size="large" edge="start" color="inherit" >
                                <img src={Home} style={{maxWidth: "25px", maxHeight: "25px", backgroundColor: "transparent"}}/>
                            </IconButton>
                        </Link>
                        </>
                    )}
                </Toolbar>
            </AppBar>
        </Box>
    )
}

export default TopBar
