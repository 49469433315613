import React from "react"
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom"
import FaqPage from "./FaqPage"
import Home from "./Home"
import SettingsPage from "./account/SettingsPage"
import PredictionDetailPage from "./prediction-detail-page/PredictionDetailPage"
import Profile from "./profile/Profile"
import { createTheme, responsiveFontSizes, ThemeProvider } from "@mui/material"
import AuthProvider from "./account/AuthProvider"

const theme = responsiveFontSizes(
  createTheme({
    palette: {
      primary: {
        light: '#6d6d6d',
        main: '#424242',
        dark: '#1b1b1b'
      }
    }
  })
)

const App = () => {

  return (
    <React.StrictMode>
      <Router>
        <ThemeProvider theme={theme}>
        <div>
          {/* A <Switch> looks through its children <Route>s and
          renders the first one that matches the current URL. */}
          <Switch>
            <AuthProvider>
              <Route path="/user/:username">
                <Profile/>
              </Route>
              <Route path="/predictions/:id" children={<PredictionDetailPage />}/>
              <Route path="/account">
                <SettingsPage />
              </Route>
              <Route path="/faq">
                <FaqPage />
              </Route>
              <Route exact path="/">
                <Home />
              </Route>
            </AuthProvider>
          </Switch>
        </div>
        </ThemeProvider>
      </Router>
    </React.StrictMode>
  );
}

export default App
