import { Grid, Typography } from "@mui/material"
import ChangePassword from "./ChangePassword";

type Props = {}

const AccountSettings: React.FC<Props> = ({}) => {
    return (
        <>
        <Grid item mt={5}>
            <Typography variant="h4" color="textPrimary" align="center" style={{width: "100%"}} gutterBottom>
              Account Settings
            </Typography>
        </Grid>

        <Grid item>
            <Typography variant="h5" gutterBottom>
                Change password
            </Typography>
            <ChangePassword />
        </Grid>
        </>
    )
}

export default AccountSettings
