import { useState } from "react";
import { IconButton, InputAdornment, TextField } from "@mui/material"
import { Visibility, VisibilityOff } from "@mui/icons-material";

type InputProps = {
    textFieldCommonProps: any;
    onPasswordChange: (newPassword: string) => void;
    errorText: string;
    helperText: string;
}

const PasswordTextInput: React.FC<InputProps> = ({
    textFieldCommonProps,
    onPasswordChange,
    errorText,
    helperText
}) => {

    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);

    return (
        <TextField
            {...textFieldCommonProps}
            id="password"
            label="Password"
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={(e) => {
                setPassword(e.target.value);
                onPasswordChange(e.target.value);
            }}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle-password-visibility"
                            edge="end"
                            onClick={() => setShowPassword(!showPassword)}
                        >
                            {showPassword ? <VisibilityOff/> : <Visibility/>}
                        </IconButton>
                    </InputAdornment> 
                )
            }}
            inputProps={{
                minLength: 8
            }}
            error={errorText}
            helperText={helperText}
        />
    )
}

export default PasswordTextInput;