import React, { ChangeEvent, useState, FormEvent }  from "react";
import { Box } from "@mui/system"
import { FormHelperText, TextField, CircularProgress, IconButton, InputAdornment, InputLabel, FormControl, Button, Typography, Alert } from "@mui/material"
import { ConstructionOutlined, Visibility, VisibilityOff } from "@mui/icons-material";
import OutlinedInput from '@mui/material/OutlinedInput';
import { changePassword } from "../requests";

type FormState = "Entry" | "Submitting" | "SubmitSuccess" | "SubmitError";

const ChangePassword: React.FC<{}> = ({}) => {

  const handleOnSubmit = (event: FormEvent<HTMLInputElement>) => {
    event.preventDefault();
    setFormState("Submitting")

    changePassword({old_password: oldPassword, new_password: newPassword})
     .then((data) => {
       setOldPasswordError("");
       setNewPasswordError("");
       setOldPassword("");
       setNewPassword("");
       setFormState("SubmitSuccess")
     })
     .catch((error) => {
       console.log(error)
       setFormState("SubmitError")
       setOldPasswordError(error.response.data.old_password ? error.response.data.old_password : "")
       setNewPasswordError(error.response.data.new_password ? error.response.data.new_password : "")
     })
  }

  const [formState, setFormState] = useState<FormState>("Entry");
  const [oldPassword, setOldPassword] = useState("");
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [oldPasswordError, setOldPasswordError] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");

  const onNewPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormState("Entry")
    setNewPassword(e.target.value);
  }
  const onOldPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormState("Entry")
    setOldPassword(e.target.value);
  }

  const submitLabel = formState === "Submitting" ? (
    <CircularProgress size={20} />
  ) : (
    <Typography fontSize="small">
      Update password
    </Typography>  
  )
  
  return (
    <Box component="form" onSubmit={handleOnSubmit} sx={{ display: 'flex', flexWrap: 'wrap', flexDirection: "column" }}>
      <FormControl sx={{ mt: 1, width: '25ch' }}>
            <InputLabel htmlFor="outlined-adornment-password">Old Password</InputLabel>
            <OutlinedInput
                autoFocus
                id="oldPassword"
                label="Old Password"
                type={showOldPassword ? "text" : "password"}
                value={oldPassword}
                onChange={onOldPasswordChange}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle-password-visibility"
                            edge="end"
                            onClick={() => setShowOldPassword(!showOldPassword)}
                        >
                            {showOldPassword ? <VisibilityOff/> : <Visibility/>}
                        </IconButton>
                    </InputAdornment> 
                }
            />
            {oldPasswordError !== "" && (
            <FormHelperText error id="accountId-error">
              {oldPasswordError}
            </FormHelperText>
  )}
        </FormControl>
        <FormControl sx={{ mt: 1, width: '25ch' }}>
            <InputLabel htmlFor="outlined-adornment-password">New Password</InputLabel>
            <OutlinedInput
                id="newPassword"
                label="New Password"
                type={showNewPassword ? "text" : "password"}
                value={newPassword}
                onChange={onNewPasswordChange}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle-password-visibility"
                            edge="end"
                            onClick={() => setShowNewPassword(!showNewPassword)}
                        >
                            {showNewPassword ? <VisibilityOff/> : <Visibility/>}
                        </IconButton>
                    </InputAdornment> 
                }
            />
            {newPasswordError !== "" && (
            <FormHelperText error id="accountId-error">
              {newPasswordError}
            </FormHelperText>)}
      </FormControl>
      <FormControl sx={{ mt: 1, width: '25ch' }}>
          {formState === "SubmitSuccess" && <Alert sx={{mb: 1}} severity="success">Password updated</Alert>}
          {formState === "SubmitError" && <Alert sx={{mb: 1}} severity="error">Error. Please try again later</Alert>}
          <Button
            type="submit"
            fullWidth
            disabled={formState === "Submitting"}
            variant="contained" >
            {submitLabel}
          </Button>
      </FormControl>
    </Box>
    );
}


export default ChangePassword;
